import { Dictonary } from './types';

export const de: Dictonary = {
  // JudgeRun
  judging: 'Wertung',
  selectcompetitor: 'Teilnehmer wählen',
  driver: 'Fahrer',
  gate: 'Tor',
  brokenPostText: 'gebrochen',
  gateNotPassedText: 'ausgelassen',
  start: 'Start',
  stopTimer: 'Stop',
  resumeTimer: 'Fortsetzen',
  finish: 'Fertig',
  finishRunConfirm: 'Wertung wirklich abschließen? Nachträgliche Änderung nicht möglich!',
  dnfConfirm: 'Teilnehmer wirklich als ausgeschieden markieren und Wertung abbrechen?',

  // ResultMonitor
  resultTableTitle: 'Ergebnisse',
  overallPoints: 'Gesamtpunkte',
  time: 'Zeit',
  autorenewOnTooltip: 'Automatische Aktualisierung ist aktiv',
  autoRenewOffTooltip: 'Automatische Aktualisierung ist ausgeschaltet',

  // SignInForm
  loginPassword: 'Passwort',
  loginFailMsg: 'Name oder Passwort falsch',

  // stdForm
  fieldRequiredHint: 'Feld darf nicht leer sein',
  agree: 'Bestätigen',
  cancel: 'Abbrechen',
  continue: 'Weiter',
  reset: 'Zurücksetzen',
  submit: 'Speichern',
  new: 'Neu',
  submitSuccess: 'Speichern erfolgreich',
  submitError: 'Speichern fehlgeschlagen',
  dataFetchError: 'Daten laden fehlgeschlagen',
  deleteError: 'Löschen fehlgeschlagen',

  deleteConfirmQuestion: (name: string) =>
    `"${name}" wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!`,

  // MuiDataTables
  noDataFound: 'Keine Daten gefunden',
  columnHeaderTooltip: (label: string) => `Nach ${label} sortieren`,
  nextPage: 'Nächste Seite',
  previousPage: 'Vorherige Seite',
  rowsPerPage: 'Zeilen pro Seite:',
  displayRows: 'von',
  jumpToPage: 'Seite',
  search: 'Suchen',
  print: 'Drucken',
  viewColumns: 'sichtbare Spalten',
  filter: 'Filter',
  all: 'Alle',

  // Competition
  competition: 'Veranstaltung',

  // CompetitionForm
  compName: 'Veranstaltungsname',
  compStartDate: 'Veranstaltungsbeginn',
  compEndDate: 'Veranstaltungsende',
  compConfiguration: 'Wettbewerbskonfiguration',
  gateCount: 'Anzahl Tore',
  timelimit: 'Zeitlimit',
  touchPostPoints: 'Punkte Berührung',
  breakPostPoints: 'Punkte Gebrochen',
  gateNotPassedPoints: 'Punkte Ausgelassen',
  useHandPoints: 'Punkte Hand',
  leftPostColor: 'Torfarbe Links',
  rightPostColor: 'Torfarbe Rechts',
  specialStage: 'Sonderprüfung',
  name: 'Name',
  startGate: 'Starttor',
  endGate: 'Endtor',
  targetTime: 'Sollzeit',
  vehicleWidth: 'Fahrzeugbreite',
  vehicleHeight: 'Fahrzeughöhe',
  vehicleWheelDiameter: 'Raddurchmesser',

  // Competitors
  competitors: 'Teilnehmer',
  competitorNumber: 'Teilnehmer Nr.',
  group: 'Gruppe',
  startTime: 'Startzeit',

  // CompetitorImporter
  importCompetitors: 'Teilnehmer Import',
  importCompetitorsFileDescription:
    'Bitte zu importierende .csv Datei wählen (muss Spalten "Vorname" und "Nachname" beinhalten)',
  selectFile: 'Datei auswählen',
  readyForImport: 'bereit für Import',
  newGroupName: 'Name der neuen Gruppe',
  addToExistingGroup: 'zu vorhandener Gruppe hinzufügen',
  firstStartTime: 'Erster Start',
  startInterval: 'Startintervall',
  csvParserError: '.csv Datei konnte nicht geparsed werden',
  requiredColumnMissing: (columnName: string) => `Ungültige Datei: Spalte ${columnName} fehlt!`,
  emptyRecordError: 'Ungültige Datei: Beinhaltet Einträge mit leerem Namen',

  // UserTable
  user: 'Benutzer',
  users: 'Benutzer',

  // UserForm
  firstName: 'Vorname',
  lastName: 'Nachname',
  loginName: 'Login Name',
  rockcrawlerDeName: 'Rockcrawler.de Name',
  roles: 'Nutzerrollen',

  // PenaltyBonusInput
  lowerThreshold: 'Unterer Grenzwert',
  upperThreshold: 'Oberer Grenzwert',
  lowerPenaltyDelta: 'Δ unten',
  upperPenaltyDelta: 'Δ oben',
  pointsPerLowerDelta: 'Punkte pro unteres Δ',
  pointsPerUpperDelta: 'Punkte pro oberes Δ',
};

import { AxiosResponse } from 'axios';
import { Competition } from 'digital-judge-base/src/types/Competition';
import axiosInstance from '../../../config/axiosConfig';

const findCompetition = async (id: string) => {
  try {
    const response: AxiosResponse<Competition> = await axiosInstance.get(
      '/competitions/competition',
      { params: { id } }
    );
    if (!response.data) {
      console.log('getCompetition fehlgeschlagen');
      return null;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default findCompetition;

import { useContext } from 'react';
import { LanguageContext } from '../LanguageProvider';

import { LanguageOption } from '../languages';

export default function LanguageSelector() {
  const { userLanguage, onUserLanguageChange: userLanguageChange } = useContext(LanguageContext);

  return (
    <select
      onChange={event => userLanguageChange(event.target.value as LanguageOption)}
      value={userLanguage}
    >
      {Object.entries(LanguageOption).map(([id, name]) => (
        <option key={id} value={name}>
          {id}
        </option>
      ))}
    </select>
  );
}

import { CSSProperties, useState } from 'react';
import { TextField } from '@mui/material';

import PickerDialog from './PickerDialog';

interface Props {
  value: string;
  onChange: (newVal: string) => void;
  label?: string;
  style?: CSSProperties;
  helperText?: string;
}

function ColorPicker(props: Props) {
  const { value, onChange, label, style, helperText } = props;

  const [showPicker, setShowPicker] = useState(false);

  return (
    <>
      <TextField
        label={label}
        onClick={() => setShowPicker(true)}
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        InputProps={{ style: { color: value } }}
        style={style}
        helperText={helperText}
      />
      {showPicker && (
        <PickerDialog
          value={value}
          onClose={() => {
            setShowPicker(false);
            onChange(value);
          }}
          onChange={c => {
            onChange(c.hex);
          }}
        />
      )}
    </>
  );
}

ColorPicker.defaultProps = {
  label: '',
  style: {},
  helperText: undefined,
};

export default ColorPicker;

import axiosInstance from '../../../config/axiosConfig';

export const deleteCompetitor = async (compId: string, driverId: string) => {
  try {
    await axiosInstance.delete('/competitions/competition/competitor', {
      params: { compId, driverId },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

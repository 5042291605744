import { AxiosResponse } from 'axios';
import { User } from 'digital-judge-base/src/types/User';
import axiosInstance from '../../../config/axiosConfig';

const fetchUsers = async () => {
  try {
    const response: AxiosResponse<User[]> = await axiosInstance.get('/users');
    if (!response.data) {
      console.log('fetchUsers fehlgeschlagen');
      return null;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default fetchUsers;

import { SnackbarProvider } from 'notistack';
import { ContentRouter } from './ContentRouter';
import { LanguageProvider } from '../LanguageProvider';
import MenuAppBar from './MenuAppBar';
import { UserProvider } from './Auth/UserProvider';

function App() {
  return (
    <LanguageProvider>
      <UserProvider>
        <MenuAppBar />
        <SnackbarProvider maxSnack={3}>
          <div style={{ padding: '10px' }}>
            <ContentRouter />
          </div>
        </SnackbarProvider>
      </UserProvider>
    </LanguageProvider>
  );
}

export default App;

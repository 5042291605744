import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export default function AddButton(props: Props) {
  const { label, onClick, disabled } = props;

  return (
    <Button
      size="small"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      startIcon={<AddIcon />}
    >
      {label}
    </Button>
  );
}

AddButton.defaultProps = {
  disabled: false,
};

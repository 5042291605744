import { useContext, useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';

import { UserRole } from 'digital-judge-base/src/types/User';
import { IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  Competition,
  CompetitorFormData,
  CompetitorInfo,
} from 'digital-judge-base/src/types/Competition';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import insertUser from '../../Users/services/insertUser';
import fetchCompetitors from '../services/fetchCompetitors';
import { LanguageContext } from '../../../LanguageProvider';
import addCompetitors from '../services/addCompetitors';
import findCompetition from '../services/findCompetition';
import { CompetitorImporter } from './CompetitorImporter';
import DeleteButton from '../../../components/DeleteButton';
import { deleteCompetitor } from '../services/deleteCompetitor';
import { getMuiTableBaseOptions, getMuiTableTheme } from '../../../config/MuiTableConfig';
import CompetitorForm from './CompetitorForm';
import updateCompetitor from '../services/updateCompetitor';
import AddGroupDialog from './AddGroupDialog';
import AddButton from '../../../components/AddButton';

function Competitors() {
  const urlParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { dictionary } = useContext(LanguageContext);

  const [competition, setCompetition] = useState<Competition | null>();
  const [drivers, setDrivers] = useState<CompetitorInfo[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<CompetitorFormData | null>(null);
  const [addGroupDialog, setAddGroupDialog] = useState<boolean>(false);

  const fetchDriverList = async () => {
    if (!urlParams.comp) {
      setDrivers([]);
      return;
    }
    const list = await fetchCompetitors(urlParams.comp);
    if (list) {
      setDrivers(list);
    }
  };

  useEffect(() => {
    fetchDriverList();
  }, [urlParams]);

  const findComp = async () => {
    if (!urlParams.comp) {
      return;
    }
    const comp = await findCompetition(urlParams.comp);
    setCompetition(comp);
  };

  useEffect(() => {
    findComp();
  }, []);

  const handleDriverSubmit = async (data: CompetitorFormData) => {
    if (!urlParams.comp) {
      return;
    }

    if (!data._id) {
      const newDriver = await insertUser({ ...data, roles: [UserRole.Driver], vehicleIds: [] });
      if (newDriver?._id) {
        const success = await addCompetitors(urlParams.comp, data.groupId, [newDriver._id]);
        if (!success) {
          enqueueSnackbar(dictionary.submitError, { variant: 'error' });
        }
      } else {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
      }
    } else {
      const success = await updateCompetitor(urlParams.comp, data);
      if (!success) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
      }
    }

    fetchDriverList();

    setSelectedDriver(null);
  };

  const onEditClicked = (driverId: String) => {
    const driver = drivers.find(d => d.user._id === driverId);
    if (driver) {
      setSelectedDriver({ ...driver.user, groupId: driver.groupId, startTime: driver.startTime });
    }
  };

  const onDeleteClicked = async (driverId: string) => {
    if (!urlParams.comp) {
      return;
    }

    const success = await deleteCompetitor(urlParams.comp, driverId);
    if (!success) {
      enqueueSnackbar(dictionary.deleteError, { variant: 'error' });
      return;
    }
    fetchDriverList();
  };

  const handleAddGroupDialogClose = (updatedComp: Competition | undefined) => {
    setAddGroupDialog(false);
    if (updatedComp) {
      setCompetition(updatedComp);
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: dictionary.competitorNumber,
      options: { filter: false },
    },
    {
      name: dictionary.group,
      options: {
        filter: true,
        customBodyRender: (value: string) =>
          competition?.competitorGroups.find(g => g._id === value)?.name,
      },
    },
    {
      name: dictionary.startTime,
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          if (!value) {
            return undefined;
          }
          return format(new Date(value), 'dd.MM.yy HH:mm');
        },
        sortCompare: order => (obj1, obj2) => {
          const val1 = new Date(obj1.data);
          const val2 = new Date(obj2.data);
          return (val1.getTime() - val2.getTime()) * (order === 'asc' ? 1 : -1);
        },
      },
    },
    {
      name: dictionary.firstName,
      options: { filter: false },
    },
    {
      name: dictionary.lastName,
      options: { filter: false },
    },
    {
      name: dictionary.rockcrawlerDeName,
      options: { filter: false, display: false },
    },
    {
      name: '',
      options: {
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        searchable: false,
        customBodyRender: (value: string) => {
          const driver = drivers.find(d => d.driverId === value);
          if (!driver) {
            return 'INTERNAL_ERROR';
          }
          return (
            <div style={{ display: 'flex', gap: '10px' }}>
              <IconButton size="small" color="primary" onClick={() => onEditClicked(value)}>
                <EditIcon />
              </IconButton>
              <DeleteButton
                confirmName={`${driver.user.firstName} ${driver.user.lastName}`}
                onClick={() => onDeleteClicked(value)}
              />
            </div>
          );
        },
      },
    },
  ];

  const data = drivers.map(d => [
    d.competitorNo,
    d.groupId,
    d.startTime,
    d.user.firstName,
    d.user.lastName,
    d.user.rockcrawlerDeName,
    d.user._id,
  ]);

  const options: MUIDataTableOptions = {
    ...getMuiTableBaseOptions(dictionary),
    download: true,
    filter: true,
    print: true,
    search: true,
    sort: true,
    viewColumns: true,
    customToolbar: () => (
      <div style={{ display: 'inline-flex', gap: '5px' }}>
        <AddButton
          label={dictionary.driver}
          onClick={() => {
            setSelectedDriver({
              firstName: '',
              lastName: '',
              rockcrawlerDeName: '',
              groupId: competition?.competitorGroups[0]?._id ?? '',
            });
          }}
          disabled={!competition || competition?.competitorGroups.length === 0}
        />
        <AddButton
          onClick={() => setAddGroupDialog(true)}
          disabled={!competition}
          label={dictionary.group}
        />
        <CompetitorImporter
          compId={urlParams.comp}
          onImportDone={() => {
            findComp();
            fetchDriverList();
          }}
        />
      </div>
    ),
    onDownload: (buildHead, buildBody, cols, dat) => {
      const customData = dat.map((d: any) => ({
        ...d,
        data: d.data.map((val: any, index: number) => {
          if (index === 1) {
            return competition?.competitorGroups.find(g => g._id === val)?.name;
          }
          if (index === 2 && val) {
            return format(new Date(val), 'dd.MM.yy HH:mm');
          }
          return val;
        }),
      }));
      return buildHead(cols) + buildBody(customData);
    },
  };

  return (
    <>
      {selectedDriver ? (
        <CompetitorForm
          compId={competition?._id}
          initialData={selectedDriver}
          onCancel={() => setSelectedDriver(null)}
          onSubmit={handleDriverSubmit}
        />
      ) : (
        <>
          <ThemeProvider theme={getMuiTableTheme()}>
            <MUIDataTable
              title={`${competition?.name}: ${dictionary.competitors}`}
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          <AddGroupDialog
            open={addGroupDialog}
            compId={String(competition?._id)}
            onClose={handleAddGroupDialogClose}
          />
        </>
      )}
    </>
  );
}

export default Competitors;

import { Button, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useContext } from 'react';
import { LanguageContext } from '../LanguageProvider';

interface Props {
  open: boolean;
  text: string;
  onSubmit: (agree: boolean) => void;
}

export default function ConfirmationDialog(props: Props) {
  const { open, text, onSubmit } = props;

  const { dictionary } = useContext(LanguageContext);

  return (
    <Dialog open={open} onClose={() => onSubmit(false)}>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSubmit(false)}>{dictionary.cancel}</Button>
        <Button onClick={() => onSubmit(true)} autoFocus>
          {dictionary.agree}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

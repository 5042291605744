import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Competition, specialStageTimeParam } from 'digital-judge-base/src/types/Competition';
import { CSSProperties, useContext } from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import { LanguageContext } from '../../LanguageProvider';
import PenaltyBonusInput from './PenaltyBonusInput';

interface Style {
  container: CSSProperties;
  rowContainer: CSSProperties;
  textField: CSSProperties;
}

const style: Style = {
  container: {
    padding: '10px',
    border: '1px solid lightgray',
    borderRadius: '5px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
  textField: {
    width: '150px',
  },
};

interface Props {
  control: Control<Competition, object>;
  getValues: () => Competition;
  compHasResults: boolean;
}

const initialSpecialStage = {
  name: '',
  startingAtGate: 1,
  endingAtGate: 2,
  timeConf: {
    name: specialStageTimeParam,
    unit: 's',
    lowerThreshold: 0,
    upperThreshold: 0,
    lowerPenaltyDelta: 0,
    pointsPerLowerDelta: 0,
    upperPenaltyDelta: 0,
    pointsPerUpperDelta: 0,
  },
};

function CompConfInput(props: Props) {
  const { dictionary } = useContext(LanguageContext);
  const { control, getValues, compHasResults } = props;

  const { fields: penaltyBonusFields /* , append, remove */ } = useFieldArray({
    control,
    name: 'compConfig.specialPenaltyOrBonus',
  });

  const {
    fields: specialStageFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'compConfig.specialStages',
  });

  const addSpecialStage = () => {
    append(JSON.parse(JSON.stringify(initialSpecialStage)));
  };

  return (
    <div style={style.container}>
      {dictionary.compConfiguration}
      <Grid container justifyContent="center" spacing={2} sx={{ marginTop: '0px' }}>
        <Grid item sm={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <div style={style.rowContainer}>
              <Controller
                name="compConfig.gateCount"
                control={control}
                rules={{ min: 1 }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    sx={style.textField}
                    type="number"
                    label={dictionary.gateCount}
                    value={value}
                    onChange={onChange}
                    disabled={compHasResults}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name="compConfig.timelimit_s"
                control={control}
                rules={{ min: 0 }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    sx={style.textField}
                    type="number"
                    label={dictionary.timelimit}
                    value={value}
                    onChange={onChange}
                    disabled={compHasResults}
                    error={!!error}
                    helperText={error ? error.message : null}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">s</InputAdornment>,
                    }}
                  />
                )}
              />
            </div>

            <div style={style.rowContainer}>
              <Controller
                name="compConfig.touchPostPoints"
                control={control}
                rules={{ min: 0 }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    sx={style.textField}
                    type="number"
                    label={dictionary.touchPostPoints}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name="compConfig.breakPostPoints"
                control={control}
                rules={{ min: 0 }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    sx={style.textField}
                    type="number"
                    label={dictionary.breakPostPoints}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </div>

            <div style={style.rowContainer}>
              <Controller
                name="compConfig.notPassGatePoints"
                control={control}
                rules={{ min: 0 }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    sx={style.textField}
                    type="number"
                    label={dictionary.gateNotPassedPoints}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name="compConfig.useHandPoints"
                control={control}
                rules={{ min: 0 }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    sx={style.textField}
                    type="number"
                    label={dictionary.useHandPoints}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </div>
            <div style={style.rowContainer}>
              <Controller
                name="compConfig.leftPostColor"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <ColorPicker
                    style={style.textField}
                    label={dictionary.leftPostColor}
                    value={value}
                    onChange={onChange}
                    helperText={error ? error.message : undefined}
                  />
                )}
              />
              <Controller
                name="compConfig.rightPostColor"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <ColorPicker
                    style={style.textField}
                    label={dictionary.rightPostColor}
                    value={value}
                    onChange={onChange}
                    helperText={error ? error.message : undefined}
                  />
                )}
              />
            </div>
          </div>
        </Grid>

        {penaltyBonusFields.map((elem, index) => (
          <Grid item sm="auto" lg={4} key={elem.name}>
            <Controller
              name={`compConfig.specialPenaltyOrBonus.${index}`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <PenaltyBonusInput value={value} onChange={onChange} />
              )}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container justifyContent="center" spacing={2} sx={{ marginTop: '0px' }}>
        <Grid item xs={12}>
          <Button
            onClick={addSpecialStage}
            disabled={compHasResults}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            {dictionary.specialStage}
          </Button>
        </Grid>
        {specialStageFields.map((elem, index) => (
          <Grid item md={6} lg={4} key={Math.random()}>
            <Controller
              name={`compConfig.specialStages.${index}`}
              control={control}
              rules={{
                validate: {
                  // TODO implement same rules also in Backend mongoose validation
                  nameNotEmpty: value =>
                    value.name !== '' || `${dictionary.fieldRequiredHint}: ${dictionary.name}`,
                  startLessThanEnd: value =>
                    value.startingAtGate < value.endingAtGate || 'start < end!',
                  isInsideGateCount: value =>
                    value.endingAtGate <= getValues().compConfig.gateCount || 'out of gatecount',
                  noDuplicates: value =>
                    !getValues().compConfig.specialStages.some(
                      val =>
                        val.name !== value.name &&
                        (val.startingAtGate === value.startingAtGate ||
                          val.endingAtGate === value.endingAtGate ||
                          val.startingAtGate === value.endingAtGate ||
                          val.endingAtGate === value.startingAtGate)
                    ) || 'duplicate start/end',
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ color: 'red' }}>
                    {error ? error.message : ''}
                  </Grid>
                  <Grid item xs={12}>
                    <IconButton onClick={() => remove(index)} disabled={compHasResults}>
                      <DeleteIcon />
                    </IconButton>
                    <TextField
                      label={dictionary.name}
                      value={value.name}
                      onChange={event => onChange({ ...value, name: event.target.value })}
                    />
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item>
                      <TextField
                        sx={style.textField}
                        label={dictionary.startGate}
                        type="number"
                        value={value.startingAtGate}
                        onChange={event =>
                          onChange({ ...value, startingAtGate: Number(event.target.value) })
                        }
                        disabled={compHasResults}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        sx={style.textField}
                        label={dictionary.endGate}
                        type="number"
                        value={value.endingAtGate}
                        onChange={event =>
                          onChange({ ...value, endingAtGate: Number(event.target.value) })
                        }
                        disabled={compHasResults}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <PenaltyBonusInput
                      value={value.timeConf}
                      onChange={event => onChange({ ...value, timeConf: event })}
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default CompConfInput;

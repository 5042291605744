import { Button, Grid, TextField } from '@mui/material';
import { CompetitorFormData } from 'digital-judge-base/src/types/Competition';

import { CSSProperties, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CustomDateTimePicker from '../../../components/CustomDateTimePicker';
import { LanguageContext } from '../../../LanguageProvider';
import { CompetitorGroupSelector } from './CompetitorGroupSelector';

interface Style {
  container: CSSProperties;
}

const style: Style = {
  container: {
    marginTop: '30px',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
  },
};

interface Props {
  compId: string | undefined;
  initialData: CompetitorFormData;
  onCancel: () => void;
  onSubmit: (data: CompetitorFormData) => void;
}

function CompetitorForm(props: Props) {
  const { compId, initialData, onCancel, onSubmit } = props;

  const { dictionary } = useContext(LanguageContext);
  const { control, reset, handleSubmit } = useForm({
    defaultValues: initialData,
  });

  return (
    <form style={style.container} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="groupId"
        control={control}
        rules={{ required: dictionary.fieldRequiredHint }}
        render={({ field: { onChange, value } }) => (
          <CompetitorGroupSelector
            compId={compId}
            selectedGroupId={value}
            onGroupSelected={onChange}
          />
        )}
      />

      <Controller
        name="startTime"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomDateTimePicker label={dictionary.startTime} value={value} onChange={onChange} />
        )}
      />

      <Controller
        name="firstName"
        control={control}
        rules={{ required: dictionary.fieldRequiredHint }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.firstName}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        rules={{ required: dictionary.fieldRequiredHint }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.lastName}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="rockcrawlerDeName"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.rockcrawlerDeName}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Grid container spacing={2} justifyContent="center">
        <Grid item sm="auto">
          <Button onClick={onCancel}>{dictionary.cancel}</Button>
        </Grid>
        <Grid item sm="auto">
          <Button type="reset" onClick={() => reset()}>
            {dictionary.reset}
          </Button>
        </Grid>
        <Grid item sm="auto">
          <Button type="submit">{dictionary.submit}</Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default CompetitorForm;

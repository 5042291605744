import axiosInstance from '../../../config/axiosConfig';

const logoutUser = async () => {
  try {
    await axiosInstance.get('users/logout');
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default logoutUser;

import { InputAdornment, TextField } from '@mui/material';
import {
  PenaltyOrBonusThreshold,
  specialStageTimeParam,
  vehicleHeightParam,
  vehicleWheelDiaParam,
  vehicleWidthParam,
} from 'digital-judge-base/src/types/Competition';
import { CSSProperties, useContext } from 'react';
import { LanguageContext } from '../../LanguageProvider';

interface Style {
  container: CSSProperties;
  rowContainer: CSSProperties;
  textField: CSSProperties;
}

const style: Style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '10px',
    border: '1px solid lightgray',
    borderRadius: '5px',
    width: 'auto',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
  textField: {
    width: '150px',
  },
};

interface Props {
  value: PenaltyOrBonusThreshold;
  onChange: (newVal: PenaltyOrBonusThreshold) => void;
}

function PenaltyBonusInput(props: Props) {
  const { dictionary } = useContext(LanguageContext);
  const { value, onChange } = props;

  const getLabel = (val: PenaltyOrBonusThreshold) => {
    if (val.name === vehicleWidthParam) {
      return dictionary.vehicleWidth;
    }
    if (val.name === vehicleHeightParam) {
      return dictionary.vehicleHeight;
    }
    if (val.name === vehicleWheelDiaParam) {
      return dictionary.vehicleWheelDiameter;
    }
    if (val.name === specialStageTimeParam) {
      return dictionary.targetTime;
    }
    return val.name;
  };

  return (
    <div style={style.container}>
      {getLabel(value)}
      <div style={style.rowContainer}>
        <TextField
          sx={style.textField}
          label={dictionary.lowerThreshold}
          type="number"
          value={value.lowerThreshold}
          onChange={event => onChange({ ...value, lowerThreshold: Number(event.target.value) })}
          InputProps={{
            endAdornment: <InputAdornment position="end">{value.unit}</InputAdornment>,
          }}
        />
        <TextField
          sx={style.textField}
          label={dictionary.upperThreshold}
          type="number"
          value={value.upperThreshold}
          onChange={event => onChange({ ...value, upperThreshold: Number(event.target.value) })}
          InputProps={{
            endAdornment: <InputAdornment position="end">{value.unit}</InputAdornment>,
          }}
        />
      </div>
      <div style={style.rowContainer}>
        <TextField
          sx={style.textField}
          label={dictionary.lowerPenaltyDelta}
          type="number"
          value={value.lowerPenaltyDelta}
          onChange={event => onChange({ ...value, lowerPenaltyDelta: Number(event.target.value) })}
          InputProps={{
            endAdornment: <InputAdornment position="end">{value.unit}</InputAdornment>,
          }}
        />
        <TextField
          sx={style.textField}
          label={dictionary.upperPenaltyDelta}
          type="number"
          value={value.upperPenaltyDelta}
          onChange={event => onChange({ ...value, upperPenaltyDelta: Number(event.target.value) })}
          InputProps={{
            endAdornment: <InputAdornment position="end">{value.unit}</InputAdornment>,
          }}
        />
      </div>
      <div style={style.rowContainer}>
        <TextField
          sx={style.textField}
          label={dictionary.pointsPerLowerDelta}
          type="number"
          value={value.pointsPerLowerDelta}
          onChange={event =>
            onChange({ ...value, pointsPerLowerDelta: Number(event.target.value) })
          }
        />
        <TextField
          sx={style.textField}
          label={dictionary.pointsPerUpperDelta}
          type="number"
          value={value.pointsPerUpperDelta}
          onChange={event =>
            onChange({ ...value, pointsPerUpperDelta: Number(event.target.value) })
          }
        />
      </div>
    </div>
  );
}

export default PenaltyBonusInput;

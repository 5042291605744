import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Competition } from 'digital-judge-base/src/types/Competition';
import { useContext, useEffect, useState } from 'react';
import fetchCompetitions from '../domain/Competitions/services/fetchCompetitions';
import { LanguageContext } from '../LanguageProvider';

interface Props {
  onChange: (comp: Competition) => void;
  competitionId: string;
}

function CompetitionSelector(props: Props) {
  const { onChange, competitionId } = props;

  const { dictionary } = useContext(LanguageContext);

  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [selectedComp, setSelectedComp] = useState<Competition | ''>('');

  const fetchCompList = async () => {
    const list = await fetchCompetitions();
    if (list) {
      setCompetitions(list);
    }
  };

  useEffect(() => {
    fetchCompList();
  }, []);

  useEffect(() => {
    const selected = competitions.find(comp => comp._id === competitionId);
    if (!selected?._id) {
      setSelectedComp('');
      return;
    }
    setSelectedComp(selected);
  }, [competitionId, competitions]);

  const onCompSelected = (compId: string) => {
    const selected = competitions.find(comp => comp._id === compId);
    if (!selected?._id) {
      return;
    }
    setSelectedComp(selected);
    onChange(selected);
  };

  return (
    <FormControl style={{ minWidth: '150px' }} error={!selectedComp}>
      <InputLabel id="compSelID">{dictionary.compName}</InputLabel>
      <Select
        labelId="compSelID"
        label={dictionary.compName}
        onChange={event => onCompSelected(event.target.value)}
        renderValue={val => competitions.find(comp => comp._id === val)?.name}
        value={selectedComp ? selectedComp._id : ''}
      >
        {competitions.map(comp => (
          <MenuItem value={comp._id} key={comp._id}>
            {comp.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default CompetitionSelector;

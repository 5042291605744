import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { LanguageContext } from '../LanguageProvider';

type Props = {
  importButtonLabel: string;
  acceptedFiletype: string;
  onFileLoad: (data: string) => void;
};

/** Button mit Filedialog zum Einlesen von textbasierterten Daten */
export default (props: Props) => {
  const { importButtonLabel, acceptedFiletype, onFileLoad } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { dictionary } = useContext(LanguageContext);

  const onFileChange = (event: any) => {
    const reader = new FileReader();

    reader.onload = () => {
      let data = '';
      try {
        if (!reader.result) {
          enqueueSnackbar(dictionary.dataFetchError, { variant: 'error' });
          return;
        }
        data = reader.result?.toString();
      } catch (error) {
        enqueueSnackbar(dictionary.dataFetchError, { variant: 'error' });
        console.log(error);
        return;
      }
      onFileLoad(data);
    }; // END reader.onload()

    // TODO make encoding selectable?
    reader.readAsText(event.target.files[0], 'windows-1252'); // ANSI encoded
  };

  return (
    <div>
      <input
        accept={`.${acceptedFiletype}`}
        style={{ display: 'none' }}
        id="contained-button-file"
        onChange={onFileChange}
        type="file"
        value=""
      />
      <label htmlFor="contained-button-file">
        <Button size="small" variant="contained" component="span">
          {importButtonLabel}
        </Button>
      </label>
    </div>
  );
};

import { Competition } from 'digital-judge-base/src/types/Competition';
import axiosInstance from '../../../config/axiosConfig';

const updateCompetition = async (data: Competition) => {
  try {
    await axiosInstance.post('/competitions/competition', { updateQuery: data, id: data._id });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default updateCompetition;

import { Vehicle } from 'digital-judge-base/src/types/Vehicle';
import axiosInstance from '../../../config/axiosConfig';

const updateVehicle = async (id: string, vehicle: Vehicle) => {
  try {
    await axiosInstance.post('/vehicles/vehicle', { id, vehicle });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default updateVehicle;

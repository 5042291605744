import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { TextField, TextFieldProps } from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../LanguageProvider';
import { LanguageOption } from '../languages';

interface Props {
  label: string;
  value: Date | undefined;
  onChange: (newVal: Date) => void;
}

function CustomDateTimePicker(props: Props) {
  const { label, value, onChange } = props;

  const { userLanguage } = useContext(LanguageContext);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={userLanguage === LanguageOption.Deutsch ? deLocale : enLocale}
    >
      <DateTimePicker
        renderInput={(tprops: JSX.IntrinsicAttributes & TextFieldProps) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...tprops} />
        )}
        label={label}
        value={value ?? null}
        onChange={(newVal: Date | null) => {
          if (newVal) {
            onChange(newVal);
          }
        }}
      />
    </LocalizationProvider>
  );
}

export default CustomDateTimePicker;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ChromePicker, ColorChangeHandler } from 'react-color';

interface Props {
  value: string;
  onChange: ColorChangeHandler;
  onClose: () => void;
}

const PickerDialog = (props: Props) => {
  const { value, onClose, onChange } = props;

  // TODO div geraffel responsive machen (dialog?)
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', zIndex: '2' }}>
        <div
          style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
          onClick={onClose}
        />
        <ChromePicker color={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default PickerDialog;

import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';

const WARN_SECS = 60;

interface Props {
  timerBase: Date;
  timerEnd?: Date;
  maxTimeSecs?: number;
}

function Timer(props: Props) {
  let { timerBase, timerEnd } = props;
  timerBase = new Date(timerBase);
  timerEnd = timerEnd ? new Date(timerEnd) : undefined;
  const { maxTimeSecs } = props;

  const getCurrentValue = () => {
    let value = Math.round((new Date().getTime() - timerBase.getTime()) / 1000);
    if (timerEnd) {
      // Uhr einfrieren wenn timerEnd gegeben
      value = Math.round((timerEnd.getTime() - timerBase.getTime()) / 1000);
    }
    return value;
  };

  const [displayValSec, setDisplayValSec] = useState(getCurrentValue());

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayValSec(getCurrentValue());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const minutes = Math.floor(displayValSec / 60);
  const secs = displayValSec % 60;
  const isNearTimeout = maxTimeSecs && displayValSec > maxTimeSecs - WARN_SECS;
  const isTimedout = maxTimeSecs && displayValSec >= maxTimeSecs;

  const getColor = () => {
    if (isTimedout) {
      return 'error';
    }
    if (isNearTimeout) {
      return 'warning';
    }

    return 'default';
  };

  return (
    <Chip
      color={getColor()}
      variant={timerEnd || isNearTimeout ? 'filled' : 'outlined'}
      label={`${minutes}:${String(secs).padStart(2, '0')}`}
    />
  );
}

Timer.defaultProps = {
  maxTimeSecs: undefined,
  timerEnd: undefined,
};

export default Timer;

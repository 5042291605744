import { Button, ButtonGroup } from '@mui/material';
import { CSSProperties } from 'react';

interface Style {
  valueButton: CSSProperties;
}

const style: Style = {
  valueButton: {
    color: '#1976d2',
  },
};

interface Props {
  disabled: boolean;
  value: number;
  onChange: (newval: number) => void;
}

function CounterIncDec(props: Props) {
  const { disabled, value, onChange } = props;
  return (
    <ButtonGroup size="small">
      <Button disabled={value <= 0 || disabled} onClick={() => onChange(value - 1)}>
        -
      </Button>
      <Button disabled style={style.valueButton}>
        {value}
      </Button>
      <Button disabled={disabled} onClick={() => onChange(value + 1)}>
        +
      </Button>
    </ButtonGroup>
  );
}

export default CounterIncDec;

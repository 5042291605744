import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { Vehicle } from 'digital-judge-base/src/types/Vehicle';

import { CSSProperties, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LanguageContext } from '../../LanguageProvider';

interface Style {
  container: CSSProperties;
}

const style: Style = {
  container: {
    marginTop: '30px',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
  },
};

interface Props {
  initialData: Vehicle;
  onCancel: () => void;
  onSubmit: (data: Vehicle) => void;
}

function VehicleForm(props: Props) {
  const { initialData, onCancel, onSubmit } = props;

  const { dictionary } = useContext(LanguageContext);
  const { control, reset, handleSubmit } = useForm({
    defaultValues: initialData,
  });

  return (
    <form style={style.container} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        rules={{ required: dictionary.fieldRequiredHint }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="name"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="width_mm"
        control={control}
        rules={{ min: 0 }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.vehicleWidth}
            type="number"
            value={value}
            onChange={onChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="height_mm"
        control={control}
        rules={{ min: 0 }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.vehicleHeight}
            type="number"
            value={value}
            onChange={onChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="wheelDiameter_mm"
        control={control}
        rules={{ min: 0 }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.vehicleWheelDiameter}
            type="number"
            value={value}
            onChange={onChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Grid container spacing={2} justifyContent="center">
        <Grid item sm="auto">
          <Button onClick={onCancel}>{dictionary.cancel}</Button>
        </Grid>
        <Grid item sm="auto">
          <Button type="reset" onClick={() => reset()}>
            {dictionary.reset}
          </Button>
        </Grid>
        <Grid item sm="auto">
          <Button type="submit">{dictionary.submit}</Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default VehicleForm;

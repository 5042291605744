import axiosInstance from '../../../config/axiosConfig';

const addCompetitors = async (
  compId: string,
  groupId: string,
  driverIds: string[],
  firstStartTime?: Date,
  startIntervalSec?: number
) => {
  try {
    await axiosInstance.post('/competitions/competition/competitors', {
      compId,
      groupId,
      driverIds,
      firstStartTime,
      startIntervalSec,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default addCompetitors;

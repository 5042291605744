import { Routes, Route } from 'react-router-dom';
import { useContext } from 'react';
import { UserRole } from 'digital-judge-base/src/types/User';
import SignInForm from './Auth/SignInForm';
import Competitions from '../domain/Competitions/Competitions';
import { UserContext } from './Auth/UserProvider';
import ResultMonitor from '../domain/ResultMonitor/ResultMonitor';
import Preparation from '../domain/JudgeRun/Preparation';
import JudgeRun from '../domain/JudgeRun/JudgeRun';
import { RoutingPath } from './Routes';
import Competitors from '../domain/Competitions/Competitors/Competitors';
import UserTable from '../domain/Users/UserTable';

export function ContentRouter() {
  const { loggedInUser } = useContext(UserContext);

  if (!loggedInUser) {
    return (
      <Routes>
        <Route path={RoutingPath.ResultMonitorBaseP} element={<ResultMonitor />} />
        <Route path={RoutingPath.ResultMonitorP} element={<ResultMonitor />} />
        <Route path={RoutingPath.LoginP} element={<SignInForm />} />
        <Route path="*" element={<ResultMonitor />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path={RoutingPath.ResultMonitorBaseP} element={<ResultMonitor />} />
      <Route path={RoutingPath.ResultMonitorP} element={<ResultMonitor />} />
      <Route path={RoutingPath.JudgeRunPrepBaseP} element={<Preparation />} />
      <Route path={RoutingPath.JudgeRunPrepP} element={<Preparation />} />
      <Route path={RoutingPath.JudgeRunP} element={<JudgeRun />} />

      {loggedInUser.roles.some(r => r === UserRole.Organizer || r === UserRole.Admin) ? (
        <>
          <Route path={RoutingPath.CompetitionP} element={<Competitions />} />
          <Route path={RoutingPath.CompetitorManagerP} element={<Competitors />} />
        </>
      ) : (
        ''
      )}

      {loggedInUser.roles.includes(UserRole.Admin) ? (
        <>
          <Route path={RoutingPath.UserManagerP} element={<UserTable />} />
        </>
      ) : (
        ''
      )}

      <Route path="*" element="" />
    </Routes>
  );
}

import { AxiosResponse } from 'axios';
import { User } from 'digital-judge-base/src/types/User';
import axiosInstance from '../../../config/axiosConfig';

export const insertDrivers = async (drivers: User[]) => {
  try {
    const response: AxiosResponse<User[]> = await axiosInstance.post('/users/drivers', { drivers });
    if (!response.data) {
      console.log('insertDrivers fehlgeschlagen');
      return null;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

import { useContext, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';

import { useSnackbar } from 'notistack';
import { AggregatedResult } from 'digital-judge-base/src/types/StageResult';
import { Grid } from '@mui/material';
import { Competition } from 'digital-judge-base/src/types/Competition';
import { ALL_VAL } from 'digital-judge-base/src/constants';
import fetchResultSummary from './services/fetchResultSummary';
import { LanguageContext } from '../../LanguageProvider';
import CompetitionSelector from '../../components/CompetitionSelector';
import { RoutingPath } from '../../App/Routes';
import { getMuiTableBaseOptions, getMuiTableTheme } from '../../config/MuiTableConfig';
import { CompetitorGroupSelector } from '../Competitions/Competitors/CompetitorGroupSelector';
import AutorenewIconButton from '../../components/AutoRenewIconButton';

const POLL_INTERVAL = 30000;

function ResultMonitor() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const { dictionary } = useContext(LanguageContext);
  const { enqueueSnackbar } = useSnackbar();

  const [groupId, setGroupId] = useState<string>(ALL_VAL);
  const [results, setResults] = useState<AggregatedResult[]>([]);
  const [isAutoRenew, setIsAutoRenew] = useState(false);

  const autoRenewRef = useRef(isAutoRenew); // isAutoRenew in Callbacks nutzbar machen
  autoRenewRef.current = isAutoRenew;

  const fetchResults = async (compId: string | undefined) => {
    if (!compId) {
      setResults([]);
      return;
    }
    const res = await fetchResultSummary(compId, groupId);
    if (!res) {
      enqueueSnackbar(dictionary.dataFetchError, { variant: 'error' });
      setResults([]);
      return;
    }
    setResults(res);
  };

  useEffect(() => {
    fetchResults(urlParams.comp);
  }, [urlParams, groupId]);

  // Auto Update
  useEffect(() => {
    const interval = setInterval(
      () => autoRenewRef.current && fetchResults(urlParams.comp),
      POLL_INTERVAL
    );
    return () => clearInterval(interval);
  }, []);

  const onCompSelected = (comp: Competition) => {
    setGroupId(ALL_VAL);
    navigate(generatePath(RoutingPath.ResultMonitorP, { comp: comp._id }));
  };

  const getResultDisplay = (driver: AggregatedResult) => {
    if (!driver.result) {
      return '-';
    }
    if (driver.result.didNotFinish) {
      return 'DNF';
    }
    return driver.result.overallPoints;
  };

  const getTimeDisplay = (driver: AggregatedResult) => {
    if (!driver.result) {
      return '-';
    }
    const minutes = Math.floor(driver.result.totalTimeSec / 60);
    const secs = driver.result.totalTimeSec % 60;
    return `${minutes}:${secs}`;
  };

  const columns: MUIDataTableColumnDef[] = [
    '#',
    dictionary.group,
    dictionary.firstName,
    dictionary.lastName,
    { name: dictionary.rockcrawlerDeName, options: { display: false } },
    dictionary.overallPoints,
    dictionary.time,
  ];

  const data = results.map(d => [
    d.position,
    d.groupName,
    d.driver.firstName,
    d.driver.lastName,
    d.driver.rockcrawlerDeName,
    getResultDisplay(d),
    getTimeDisplay(d),
  ]);

  const options: MUIDataTableOptions = {
    ...getMuiTableBaseOptions(dictionary),
    download: true,
    print: true,
    viewColumns: true,
    search: true,
    customToolbar: () => (
      <>
        <AutorenewIconButton onClick={() => setIsAutoRenew(!isAutoRenew)} isSet={isAutoRenew} />

        <CompetitorGroupSelector
          disabled={!urlParams.comp}
          compId={urlParams.comp}
          selectedGroupId={groupId}
          onGroupSelected={setGroupId}
          defaultAll
        />
      </>
    ),
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <CompetitionSelector
          onChange={onCompSelected}
          competitionId={urlParams.comp ? urlParams.comp : ''}
        />
      </Grid>
      <Grid item>
        <ThemeProvider theme={getMuiTableTheme()}>
          <MUIDataTable
            title={dictionary.resultTableTitle}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}

export default ResultMonitor;

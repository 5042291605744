import { useState, createContext } from 'react';

import { LanguageOption, dictionaryList } from './languages';
import { Dictonary } from './languages/types';

interface LangContextProvider {
  userLanguage: LanguageOption;
  dictionary: Dictonary;
  onUserLanguageChange: (selected: LanguageOption) => void;
}

export const LanguageContext = createContext<LangContextProvider>({
  userLanguage: LanguageOption.English,
  dictionary: dictionaryList[LanguageOption.English],
  onUserLanguageChange: () => {},
});

export function LanguageProvider({ children }: any) {
  const browserLang = window.localStorage.getItem('rcml-lang');
  let initialLang = LanguageOption.English;
  if (browserLang !== null) {
    const opt = Object.entries(LanguageOption).find(([, name]) => name === browserLang);
    if (opt) {
      const second = 1;
      initialLang = opt[second];
    }
  }

  const [userLanguage, setUserLanguage] = useState<LanguageOption>(initialLang);

  const provider: LangContextProvider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    onUserLanguageChange: (selected: LanguageOption) => {
      setUserLanguage(selected);
      window.localStorage.setItem('rcml-lang', selected); // Erhalte Selektierung bei Refresh
    },
  };

  return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>;
}

import { AxiosResponse } from 'axios';
import { StageResult } from 'digital-judge-base/src/types/StageResult';
import axiosInstance from '../../../config/axiosConfig';

const insertDriverResult = async (newResult: StageResult, compId: string, driverId: string) => {
  try {
    const response: AxiosResponse<StageResult> = await axiosInstance.post('/stageresults', {
      newResult,
      compId,
      driverId,
    });
    if (!response.data) {
      console.log('insertDriverResult fehlgeschlagen');
      return null;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default insertDriverResult;

import { Button, Dialog, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Competition } from 'digital-judge-base/src/types/Competition';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { LanguageContext } from '../../../LanguageProvider';
import addGroup from '../services/addGroup';

interface Props {
  open: boolean;
  compId: string;
  onClose: (updatedComp: Competition | undefined) => void;
}

export default function AddGroupDialog(props: Props) {
  const { open, compId, onClose } = props;

  const { dictionary } = useContext(LanguageContext);
  const { enqueueSnackbar } = useSnackbar();

  const [newGroupName, setNewGroupName] = useState<string>('');

  const handleSubmitClicked = async () => {
    const result = await addGroup(compId, newGroupName);
    if (!result) {
      enqueueSnackbar(dictionary.submitError, { variant: 'error' });
    }
    onClose(result);
  };

  return (
    <Dialog open={open} onClose={() => onClose(undefined)}>
      <DialogContent>
        <TextField
          label={dictionary.newGroupName}
          value={newGroupName}
          error={!newGroupName}
          onChange={event => setNewGroupName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(undefined)}>{dictionary.cancel}</Button>
        <Button onClick={handleSubmitClicked} autoFocus disabled={!newGroupName}>
          {dictionary.submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

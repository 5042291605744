import { IconButton, Dialog, DialogContent, Typography } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import packageJson from '../../package.json';

function AboutDialog() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton size="small" color="inherit" onClick={() => setOpen(true)}>
        <MoreIcon />
      </IconButton>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogContent>
          {`App-Version: ${packageJson.version}`}
          <br />
          <br />
          <Typography variant="h6">Impressum</Typography>
          <Typography>Verantwortlicher Seitenbetreiber: Stephan Pullich</Typography>
          <Typography gutterBottom>Kontakt: nennung@well-rc.de</Typography>
          <Typography variant="h6">Haftungsausschluss</Typography>
          <Typography gutterBottom>
            Die Beiträge und Inhalte werden sorgfältig recherchiert. Es wird keine Haftung für die
            Richtigkeit der zur Verfügung gestellten Informationen und Inhalte übernommen.
          </Typography>
          <Typography variant="h6">Datenschutzerklärung</Typography>
          <Typography variant="subtitle1">
            <strong>1. Datenschutz auf einen Blick</strong>
          </Typography>
          <Typography gutterBottom>
            Dieser Abschnitt gibt einen einfachen Überblick darüber, was mit Ihren personenbezogenen
            Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle
            Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
            Datenschutzerklärung.
          </Typography>
          <Typography variant="body2">
            <strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong>
          </Typography>
          <Typography gutterBottom>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
            Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
          </Typography>
          <Typography variant="body2">
            <strong>Wie erfassen wir Ihre Daten?</strong>
          </Typography>
          <Typography gutterBottom>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese freiwillig mitteilen.
            Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Formular eingeben, oder als
            Datei hochladen.
          </Typography>
          <Typography gutterBottom>
            Andere Daten werden automatisch beim Besuch der Website durch die IT-Systeme des von uns
            beauftragten Providers bzw. dessen Subunternehmer erfasst. Das sind vor allem technische
            Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
            Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
          </Typography>
          <Typography variant="body2">
            <strong>Wofür nutzen wir Ihre Daten?</strong>
          </Typography>
          <Typography gutterBottom>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
            gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
          </Typography>
          <Typography variant="body2">
            <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
          </Typography>
          <Typography gutterBottom>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck
            Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht,
            die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu
            weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum
            angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der
            zuständigen Aufsichtsbehörde zu.
          </Typography>
          <Typography variant="subtitle1">
            <strong>2. Allgemeine Hinweise und Pflichtinformationen</strong>
          </Typography>
          <Typography gutterBottom>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
            behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese Website
            benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
            sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
            Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht.
          </Typography>
          <Typography gutterBottom>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation
            per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
            Zugriff durch Dritte ist nicht möglich!
          </Typography>
          <Typography variant="body2">
            <strong>Hinweis zur verantwortlichen Stelle</strong>
          </Typography>
          <Typography gutterBottom>
            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder
            gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen
            Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet. Die Kontaktdaten der für diese
            Website Verantwortlichen Stelle, können Sie dem Impressum entnehmen.
          </Typography>
          <Typography variant="body2">
            <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
          </Typography>
          <Typography gutterBottom>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich.
            Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
            formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </Typography>
          <Typography variant="body2">
            <strong>Beschwerderecht bei der zuständigen Aufsichtsbehörde</strong>
          </Typography>
          <Typography gutterBottom>
            Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei
            der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in
            datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in
            dem unser Unternehmen seinen Sitz hat.
          </Typography>
          <Typography variant="body2">
            <strong>Recht auf Datenübertragbarkeit</strong>
          </Typography>
          <Typography gutterBottom>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung
            eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem
            gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte
            Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </Typography>
          <Typography variant="body2">
            <strong>Auskunft, Sperrung, Löschung</strong>
          </Typography>
          <Typography gutterBottom>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
            unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft
            und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
            Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
            personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen
            Adresse an uns wenden.
          </Typography>
          <Typography variant="body2">
            <strong>Widerspruch gegen Werbe-Mails</strong>
          </Typography>
          <Typography gutterBottom>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur
            Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien
            wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich
            rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </Typography>
          <Typography variant="subtitle1">
            <strong>3. Datenerfassung auf unserer Website</strong>
          </Typography>
          <Typography variant="body2">
            <strong>Cookies</strong>
          </Typography>
          <Typography gutterBottom>
            Diese Website verwendet teilweise so genannte Cookies. Cookies dienen dazu, unser
            Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine
            Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die
            meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden
            nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät
            gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
            nächsten Besuch wiederzuerkennen.
          </Typography>
          <Typography gutterBottom>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert
            werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte
            Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die
            Funktionalität einiger Teilbereiche dieser Website eingeschränkt sein.
          </Typography>
          <Typography gutterBottom>
            Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur
            Bereitstellung bestimmter, von Ihnen erwünschter Funktionen erforderlich sind, werden
            auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein
            berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und
            optimierten Bereitstellung seiner Dienste. Eine weiterführende Datensammlung außerhalb
            dieser Website wird damit nicht betrieben.
          </Typography>
          <Typography variant="body2">
            <strong>Server-Log-Dateien</strong>
          </Typography>
          <Typography gutterBottom>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
            Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
          </Typography>
          <Typography variant="body2" gutterBottom>
            - Browsertyp und Browserversion <br />
            - verwendetes Betriebssystem <br />
            - Referrer URL <br />
            - Hostname des zugreifenden Rechners <br />
            - Uhrzeit der Serveranfrage <br />
            - IP-Adresse <br />
          </Typography>
          <Typography gutterBottom>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Die
            Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen.
            Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der
            Sicherheit unserer informationstechnischen Systeme. Eine weitergehende Auswertung der
            Logfiles zu anderen Zwecken findet nicht statt. Rechtsgrundlage für die vorübergehende
            Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
          </Typography>
          {/* <Typography variant="body2">
            <strong>Registrierung auf dieser Website</strong>
          </Typography>
          <Typography gutterBottom>
            Sie können sich auf unserer Website registrieren, um zusätzliche Funktionen auf der
            Seite zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung
            des jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der
            Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden.
            Anderenfalls werden wir die Registrierung ablehnen. Für wichtige Änderungen etwa beim
            Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der
            Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
          </Typography>
          <Typography>
            Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte
            Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
            uns. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf
            unberührt. Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange
            Sie auf unserer Website registriert sind und werden anschließend gelöscht. Gesetzliche
            Aufbewahrungsfristen bleiben unberührt.
            </Typography> */}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AboutDialog;

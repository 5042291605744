import { AxiosResponse } from 'axios';
import { User } from 'digital-judge-base/src/types/User';
import axiosInstance from '../../../config/axiosConfig';

const getLoggedInUser = async () => {
  try {
    const response: AxiosResponse<User> = await axiosInstance.get('/users/auth');
    return response.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default getLoggedInUser;

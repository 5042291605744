import { useContext, useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import { User, UserRole } from 'digital-judge-base/src/types/User';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import UserForm from './UserForm';
import insertUser from './services/insertUser';
import { LanguageContext } from '../../LanguageProvider';
import updateUser from './services/updateUser';
import DeleteButton from '../../components/DeleteButton';
import fetchUsers from './services/fetchUsers';
import deleteUser from './services/deleteUser';
import { UserContext } from '../../App/Auth/UserProvider';
import { getMuiTableBaseOptions, getMuiTableTheme } from '../../config/MuiTableConfig';
import AddButton from '../../components/AddButton';

const initialUser: User = {
  firstName: '',
  lastName: '',
  loginName: '',
  rockcrawlerDeName: '',
  roles: [],
  vehicleIds: [],
};

function UserTable() {
  const { enqueueSnackbar } = useSnackbar();
  const { dictionary } = useContext(LanguageContext);
  const { loggedInUser } = useContext(UserContext);

  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const fetch = async () => {
    const list = await fetchUsers();
    if (list) {
      setUsers(
        list.filter(
          el =>
            el.roles.includes(UserRole.Admin) ||
            el.roles.includes(UserRole.Judge) ||
            el.roles.includes(UserRole.Organizer)
        )
      );
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleSubmit = async (user: User) => {
    if (!user._id) {
      const newUser = await insertUser(user);
      if (!newUser) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
        return;
      }
    } else {
      const success = await updateUser(user);
      if (!success) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
        return;
      }
    }

    fetch();
    setSelectedUser(null);
  };

  const handleEditClicked = (userId: string) => {
    const user = users.find(u => u._id === userId);
    if (user) {
      setSelectedUser(user);
    }
  };

  const handleDeleteClicked = async (userId: string) => {
    const success = await deleteUser(userId);
    if (!success) {
      enqueueSnackbar(dictionary.deleteError, { variant: 'error' });
      return;
    }
    fetch();
  };

  const columns: MUIDataTableColumnDef[] = [
    dictionary.firstName,
    dictionary.lastName,
    dictionary.loginName,
    {
      name: dictionary.rockcrawlerDeName,
      options: { display: false },
    },
    {
      name: dictionary.roles,
      options: {
        customBodyRender: (value: string[]) => value.join(', '),
      },
    },
    {
      name: '',
      options: {
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        searchable: false,
        customBodyRender: (value: string) => {
          const user = users.find(u => u._id === value);
          if (!user) {
            return 'INTERNAL_ERROR';
          }

          return (
            <div style={{ display: 'flex', gap: '10px' }}>
              <IconButton size="small" color="primary" onClick={() => handleEditClicked(value)}>
                <EditIcon />
              </IconButton>
              <DeleteButton
                confirmName={`${user.firstName} ${user.lastName}`}
                onClick={() => handleDeleteClicked(value)}
                disabled={loggedInUser?._id === user._id}
              />
            </div>
          );
        },
      },
    },
  ];
  const data = users.map(u => [
    u.firstName,
    u.lastName,
    u.loginName,
    u.rockcrawlerDeName,
    u.roles,
    u._id,
  ]);
  const options: MUIDataTableOptions = {
    ...getMuiTableBaseOptions(dictionary),
    filter: true,
    sort: true,
    viewColumns: true,
    customToolbar: () => (
      <AddButton onClick={() => setSelectedUser(initialUser)} label={dictionary.user} />
    ),
  };

  return (
    <>
      {selectedUser ? (
        <UserForm
          initialData={selectedUser}
          onCancel={() => setSelectedUser(null)}
          onSubmit={handleSubmit}
        />
      ) : (
        <>
          <ThemeProvider theme={getMuiTableTheme()}>
            <MUIDataTable
              title={dictionary.users}
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </>
      )}
    </>
  );
}

export default UserTable;

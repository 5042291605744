import { Button, Chip, Grid, Paper, Tab, Tabs, ToggleButton } from '@mui/material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AlarmIcon from '@mui/icons-material/Alarm';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { GateResult, StageResult } from 'digital-judge-base/src/types/StageResult';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { User } from 'digital-judge-base/src/types/User';
import HandIcon from '@mui/icons-material/PanTool';
import { useSnackbar } from 'notistack';
import { CompConfig, SpecialStageConfig } from 'digital-judge-base/src/types/Competition';
import GatePost, { GatePostData } from './GatePost';
import { LanguageContext } from '../../LanguageProvider';
import findUser from '../Users/services/findUser';
import findCompetition from '../Competitions/services/findCompetition';
import fetchDriverResult from './services/fetchDriverResult';
import insertDriverResult from './services/insertDriverResult';
import updateDriverResult from './services/updateDriverResult';
import startRunTimer from './services/startRunTimer';
import stopRunTimer from './services/stopRunTimer';
import CounterIncDec from '../../components/CounterIncDec';
import finishRun from './services/finishRun';
import { RoutingPath } from '../../App/Routes';
import RunTimerControl from './RunTimerControl';
import startSpecialStage from './services/startSpecialStage';
import Timer from '../../components/Timer';
import finishSpecialStage from './services/finishSpecialStage';
import resumeRunTimer from './services/resumeRunTimer';

interface Styles {
  driverInfo: CSSProperties;
  tabBar: CSSProperties;
  tabbedContent: CSSProperties;
  gateTabContainer: CSSProperties;
}

const style: Styles = {
  driverInfo: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    padding: '10px',
    borderBottom: '1px solid lightgray',
  },
  tabBar: {
    display: 'flex',
    justifyContent: 'center',
  },
  tabbedContent: {
    margin: '10px',
  },
  gateTabContainer: {
    justifyContent: 'space-evenly',
    height: '100%',
    alignItems: 'end',
  },
};

/** Komponente zum Durchführen eines Wertungslaufes */
function JudgeRun() {
  const { dictionary } = useContext(LanguageContext);
  const urlParams = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedGateIndex, setSelectedTabIndex] = useState(0);
  const [stageResult, setStageResult] = useState<StageResult | null>(null);
  const [driver, setDriver] = useState<User | null>(null);
  const [compConfig, setCompConfig] = useState<CompConfig | null>(null);

  const isStarted = !!stageResult?.startTime;

  const backToPreparation = (error?: string) => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
    navigate(generatePath(RoutingPath.JudgeRunPrepP, { comp: urlParams.comp }));
  };

  useEffect(() => {
    const fetch = async () => {
      const driverId = urlParams.driver;
      const compId = urlParams.comp;
      if (!driverId || !compId) {
        backToPreparation(dictionary.dataFetchError);
        return;
      }

      const user = await findUser(driverId);
      if (!user) {
        backToPreparation(dictionary.dataFetchError);
        return;
      }
      setDriver(user);

      const competition = await findCompetition(compId);
      if (!competition) {
        backToPreparation(dictionary.dataFetchError);
        return;
      }
      setCompConfig(competition.compConfig);

      const result = await fetchDriverResult(compId, driverId);
      if (result) {
        setStageResult(result);
      } else if (result === null) {
        // Noch nichts da, also neu anlegen
        const initialGateResult = {
          isNotPassed: false,
          isLeftPostBroken: false,
          isRightPostBroken: false,
          touchedLeftPost: 0,
          touchedRightPost: 0,
        };

        const specialStageIds = competition.compConfig.specialStages.map(el => el._id);
        if (specialStageIds.includes(undefined)) {
          backToPreparation(dictionary.dataFetchError);
          return;
        }
        const initialStageResult = {
          isFinalized: false,
          didNotFinish: false,
          handUsed: 0,
          specialStagesResults: specialStageIds.map(i => ({ stageId: i as string })),
          gateResults: Array(competition.compConfig.gateCount).fill(initialGateResult),
        };

        const res = await insertDriverResult(initialStageResult, compId, driverId);
        if (!res) {
          backToPreparation(dictionary.dataFetchError);
          return;
        }
        setStageResult(res);
      }
    };

    fetch();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const getTabLabel = (gateIndex: number) => {
    const text = `${dictionary.gate} ${gateIndex + 1}`;

    let isSpecial = false;
    if (
      compConfig?.specialStages.find(
        el => el.startingAtGate === gateIndex + 1 || el.endingAtGate === gateIndex + 1
      )
    ) {
      isSpecial = true;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
        {text}
        {isSpecial ? <AlarmIcon fontSize="small" /> : ''}
      </div>
    );
  };

  const GateTabContent = (props: {
    res: GateResult;
    disabled: boolean;
    onChange: (newVal: GateResult) => void;
    specialStageStart: SpecialStageConfig | undefined;
    specialStageEnd: SpecialStageConfig | undefined;
    fullstageResult: StageResult | null;
    onSpecialStageResultChanged: (newResult: StageResult) => void;
  }) => {
    const {
      res,
      disabled,
      onChange,
      specialStageStart,
      specialStageEnd,
      fullstageResult,
      onSpecialStageResultChanged,
    } = props;

    const onGatePostChange = (newData: GatePostData, post: 'left' | 'right') => {
      const newRes = { ...res };
      if (post === 'left') {
        newRes.isLeftPostBroken = newData.isBroken;
        newRes.touchedLeftPost = newData.touchCount;
      }
      if (post === 'right') {
        newRes.isRightPostBroken = newData.isBroken;
        newRes.touchedRightPost = newData.touchCount;
      }
      onChange(newRes);
    };

    const onAusgelassenChange = (newVal: boolean) => {
      const newRes = { ...res };
      newRes.isNotPassed = newVal;
      onChange(newRes);
    };

    const onStartSpecialStageClicked = async () => {
      if (!fullstageResult?._id || !specialStageStart?._id) {
        return;
      }
      const result = await startSpecialStage(fullstageResult._id, specialStageStart._id);
      if (!result) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
        return;
      }
      onSpecialStageResultChanged(result);
    };

    const onStopSpecialStageClicked = async () => {
      if (!fullstageResult?._id || !specialStageEnd?._id) {
        return;
      }
      const result = await finishSpecialStage(fullstageResult._id, specialStageEnd._id);
      if (!result) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
        return;
      }
      onSpecialStageResultChanged(result);
    };

    const startTime = stageResult?.specialStagesResults.find(
      r => r.stageId === specialStageStart?._id || r.stageId === specialStageEnd?._id
    )?.startTime;
    const endTime = stageResult?.specialStagesResults.find(
      r => r.stageId === specialStageStart?._id || r.stageId === specialStageEnd?._id
    )?.endTime;

    return (
      <Grid container spacing={1} style={style.gateTabContainer}>
        <Grid item sm="auto">
          <GatePost
            disabled={disabled}
            color={compConfig ? compConfig.leftPostColor : 'gray'}
            value={{ touchCount: res.touchedLeftPost, isBroken: res.isLeftPostBroken }}
            onChange={newVal => onGatePostChange(newVal, 'left')}
          />
        </Grid>

        <Grid item xs={12} sm="auto" order={{ xs: 3, sm: 2 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              justifyContent: 'center',
            }}
          >
            {startTime ? <Timer timerBase={startTime} timerEnd={endTime} /> : ''}
            {specialStageStart ? (
              <>
                <Button
                  variant="outlined"
                  onClick={onStartSpecialStageClicked}
                  disabled={
                    disabled ||
                    stageResult?.specialStagesResults.some(
                      r => r.stageId === specialStageStart._id && r.startTime
                    )
                  }
                >
                  Start {specialStageStart.name}
                </Button>
              </>
            ) : (
              ''
            )}
            {specialStageEnd ? (
              <>
                <Button
                  variant="outlined"
                  onClick={onStopSpecialStageClicked}
                  disabled={
                    disabled ||
                    stageResult?.specialStagesResults.some(
                      r => r.stageId === specialStageEnd._id && (!r.startTime || r.endTime)
                    )
                  }
                >
                  Stop {specialStageEnd.name}
                </Button>
              </>
            ) : (
              ''
            )}
            <ToggleButton
              value=""
              color="error"
              size="small"
              selected={res.isNotPassed}
              disabled={disabled}
              onChange={() => onAusgelassenChange(!res.isNotPassed)}
            >
              <AltRouteIcon />
              {dictionary.gateNotPassedText}
            </ToggleButton>
          </div>
        </Grid>

        <Grid item sm="auto" order={{ xs: 2, sm: 3 }}>
          <GatePost
            disabled={disabled}
            color={compConfig ? compConfig.rightPostColor : 'gray'}
            value={{ touchCount: res.touchedRightPost, isBroken: res.isRightPostBroken }}
            onChange={newVal => onGatePostChange(newVal, 'right')}
          />
        </Grid>
      </Grid>
    );
  };

  const onGateResultChange = async (newRes: GateResult, gateIndex: number) => {
    if (!stageResult?._id) {
      console.log('Invalid call of onGateResultChange');
      return;
    }
    const newStageResult = { ...stageResult };
    newStageResult.gateResults[gateIndex] = newRes;
    setStageResult(newStageResult);

    const res = await updateDriverResult(stageResult._id, newStageResult);
    if (!res) {
      enqueueSnackbar(dictionary.submitError, { variant: 'error' });
    }
  };

  const onHandCountChange = async (newVal: number) => {
    if (!stageResult?._id) {
      console.log('Invalid call of onGateResultChange');
      return;
    }
    const newStageResult = { ...stageResult };
    newStageResult.handUsed = newVal;
    setStageResult(newStageResult);

    const res = await updateDriverResult(stageResult._id, newStageResult);
    if (!res) {
      enqueueSnackbar(dictionary.submitError, { variant: 'error' });
    }
  };

  const onStartClicked = async () => {
    if (!stageResult?._id) {
      return;
    }
    const result = await startRunTimer(stageResult._id);
    if (!result) {
      if (!result) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
      }
      return;
    }
    setStageResult(result);
  };

  const onStopTimerClicked = async () => {
    if (!stageResult?._id) {
      return;
    }
    const result = await stopRunTimer(stageResult._id);
    if (!result) {
      if (!result) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
      }
      return;
    }
    setStageResult(result);
  };

  const onResumeTimerClicked = async () => {
    if (!stageResult?._id) {
      return;
    }
    const result = await resumeRunTimer(stageResult._id);
    if (!result) {
      if (!result) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
      }
      return;
    }
    setStageResult(result);
  };

  const onFinishClicked = async (isDnf: boolean) => {
    if (!stageResult?._id) {
      return;
    }
    const result = await finishRun(stageResult._id, isDnf);
    if (!result) {
      if (!result) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
      }
      return;
    }
    enqueueSnackbar(dictionary.submitSuccess, { variant: 'success' });
    backToPreparation();
  };

  return (
    <div>
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <Chip
            variant="outlined"
            label={`${dictionary.driver}: ${
              driver ? `${driver.firstName} ${driver.lastName}` : ''
            }`}
          />
        </Grid>
        <Grid item>
          <RunTimerControl
            stageResult={stageResult}
            timelimit_s={compConfig?.timelimit_s ?? 0}
            onStartClicked={onStartClicked}
            onStopTimerClicked={onStopTimerClicked}
            onResumeTimerClicked={onResumeTimerClicked}
            onFinishClicked={onFinishClicked}
          />
        </Grid>
      </Grid>

      {stageResult ? (
        <>
          <div style={style.tabBar}>
            <Tabs
              value={selectedGateIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {stageResult.gateResults.map((val, index) => (
                <Tab label={getTabLabel(index)} key={`TOR${index + 1}`} />
              ))}
            </Tabs>
          </div>
          <div style={style.tabbedContent}>
            <GateTabContent
              res={stageResult.gateResults[selectedGateIndex]}
              disabled={!isStarted || stageResult.isFinalized}
              onChange={newRes => onGateResultChange(newRes, selectedGateIndex)}
              specialStageStart={compConfig?.specialStages.find(
                el => el.startingAtGate === selectedGateIndex + 1
              )}
              specialStageEnd={compConfig?.specialStages.find(
                el => el.endingAtGate === selectedGateIndex + 1
              )}
              fullstageResult={stageResult}
              onSpecialStageResultChanged={newStageResult => setStageResult(newStageResult)}
            />
          </div>

          <div style={style.tabBar}>
            <Paper
              variant="outlined"
              style={{ display: 'flex', alignItems: 'center', padding: '5px', gap: '5px' }}
            >
              <HandIcon color="primary" />
              <CounterIncDec
                disabled={!isStarted || stageResult.isFinalized}
                value={stageResult.handUsed}
                onChange={newVal => {
                  onHandCountChange(newVal);
                }}
              />
            </Paper>
          </div>
        </>
      ) : (
        'Loading'
      )}
    </div>
  );
}

export default JudgeRun;

import { Button } from '@mui/material';
import { StageResult } from 'digital-judge-base/src/types/StageResult';
import { useContext, useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import Timer from '../../components/Timer';
import { LanguageContext } from '../../LanguageProvider';

interface Props {
  stageResult: StageResult | null;
  timelimit_s: number;
  onStartClicked: () => void;
  onStopTimerClicked: () => void;
  onResumeTimerClicked: () => void;
  onFinishClicked: (isDnf: boolean) => void;
}

export default function RunTimerControl(props: Props) {
  const {
    stageResult,
    timelimit_s: maxTimeSecs,
    onStartClicked,
    onStopTimerClicked,
    onResumeTimerClicked,
    onFinishClicked,
  } = props;

  const { dictionary } = useContext(LanguageContext);

  const [confirmationPending, setConfirmationPending] = useState<'none' | 'finish' | 'dnf'>('none');

  const onConfirm = (agree: boolean) => {
    if (agree && confirmationPending === 'finish') {
      onFinishClicked(false);
    }
    if (agree && confirmationPending === 'dnf') {
      onFinishClicked(true);
    }
    setConfirmationPending('none');
  };

  const getConfirmText = () => {
    if (confirmationPending === 'finish') {
      return dictionary.finishRunConfirm;
    }
    if (confirmationPending === 'dnf') {
      return dictionary.dnfConfirm;
    }
    return 'ERROR defaultcase reached';
  };

  if (!stageResult?.startTime) {
    return (
      <Button variant="outlined" size="small" onClick={onStartClicked}>
        {dictionary.start}
      </Button>
    );
  }
  const isTimedout =
    stageResult.endTime &&
    new Date(stageResult.endTime).getTime() / 1000 -
      new Date(stageResult.startTime).getTime() / 1000 >=
      maxTimeSecs;
  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Timer
        timerBase={stageResult.startTime}
        maxTimeSecs={maxTimeSecs}
        timerEnd={stageResult.endTime}
      />
      {stageResult.endTime ? (
        <>
          <Button
            variant="contained"
            size="small"
            disabled={isTimedout}
            onClick={onResumeTimerClicked}
          >
            {dictionary.resumeTimer}
          </Button>
          <Button
            variant="contained"
            size="small"
            color="success"
            disabled={isTimedout}
            onClick={() => setConfirmationPending('finish')}
            style={stageResult.isFinalized ? { visibility: 'hidden' } : {}}
          >
            {dictionary.finish}
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={() => setConfirmationPending('dnf')}
            style={stageResult.isFinalized ? { visibility: 'hidden' } : {}}
          >
            DNF
          </Button>
          <ConfirmationDialog
            open={confirmationPending !== 'none'}
            text={getConfirmText()}
            onSubmit={onConfirm}
          />
        </>
      ) : (
        <Button
          variant="contained"
          size="small"
          onClick={onStopTimerClicked}
          disabled={stageResult.specialStagesResults.some(r => !r.endTime)}
        >
          {dictionary.stopTimer}
        </Button>
      )}
    </div>
  );
}

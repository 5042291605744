import { Button, Grid, TextField } from '@mui/material';

import { Competition } from 'digital-judge-base/src/types/Competition';
import { CSSProperties, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CustomDateTimePicker from '../../components/CustomDateTimePicker';
import { LanguageContext } from '../../LanguageProvider';
import CompConfInput from './CompConfInput';
import { hasResults } from './services/hasResults';

interface Style {
  container: CSSProperties;
}

const style: Style = {
  container: {
    marginTop: '30px',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
  },
};

interface Props {
  initialData: Competition;
  onSubmit: (data: Competition) => void;
  onCancel: () => void;
}

function CompetitionForm(props: Props) {
  const { initialData, onSubmit, onCancel: onClose } = props;

  const { dictionary } = useContext(LanguageContext);
  const { control, getValues, reset, handleSubmit } = useForm<Competition>({
    defaultValues: initialData,
  });

  return (
    <form style={style.container} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        rules={{ required: dictionary.fieldRequiredHint }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.compName}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
      <Controller
        name="startDate"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomDateTimePicker
            label={dictionary.compStartDate}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="endDate"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomDateTimePicker label={dictionary.compEndDate} value={value} onChange={onChange} />
        )}
      />

      <CompConfInput
        control={control}
        compHasResults={hasResults(initialData)}
        getValues={getValues}
      />

      <Grid container spacing={2} justifyContent="center">
        <Grid item sm="auto">
          <Button onClick={onClose}>{dictionary.cancel}</Button>
        </Grid>
        <Grid item sm="auto">
          <Button type="reset" onClick={() => reset()}>
            {dictionary.reset}
          </Button>
        </Grid>
        <Grid item sm="auto">
          <Button type="submit">{dictionary.submit}</Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default CompetitionForm;

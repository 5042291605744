import { AxiosResponse } from 'axios';
import { StageResult } from 'digital-judge-base/src/types/StageResult';
import axiosInstance from '../../../config/axiosConfig';

const stopRunTimer = async (id: string) => {
  try {
    const response: AxiosResponse<StageResult> = await axiosInstance.post(
      '/stageresults/stageresult/stoptimer',
      {
        id,
      }
    );
    if (!response.data) {
      console.log('stopRunTimer fehlgeschlagen');
      return undefined;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export default stopRunTimer;

import { createTheme } from '@mui/material/styles';
import { MUIDataTableOptions } from 'mui-datatables';
import { Dictonary } from '../languages/types';

/** Basisparameter für MuiDataTable Tabellen als Standard */
export function getMuiTableBaseOptions(dictionary: Dictonary): MUIDataTableOptions {
  return {
    download: false,
    print: false,
    filter: false,
    sort: false,
    search: false,
    viewColumns: false,
    responsive: 'vertical',
    selectableRows: 'none',
    rowsPerPageOptions: [10, 15, 20, 50, 100],
    // tableBodyHeight: 'auto',
    textLabels: {
      body: {
        noMatch: dictionary.noDataFound,
        columnHeaderTooltip: (column: any) => dictionary.columnHeaderTooltip(column.label),
      },
      pagination: {
        next: dictionary.nextPage,
        previous: dictionary.previousPage,
        rowsPerPage: dictionary.rowsPerPage,
        displayRows: dictionary.displayRows, // 1-10 "of" 53
        jumpToPage: dictionary.jumpToPage,
      },
      toolbar: {
        search: dictionary.search,
        print: dictionary.print,
        viewColumns: dictionary.viewColumns,
        filterTable: dictionary.filter,
      },
      filter: {
        all: dictionary.all,
        title: dictionary.filter,
        reset: dictionary.reset,
      },
      viewColumns: {
        title: dictionary.viewColumns,
      },
    },
  };
}

export function getMuiTableTheme() {
  return createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            paddingTop: '3px',
            paddingBottom: '3px',
          },
        },
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            '&:nth-child(even)': {
              backgroundColor: '#f9f9f9',
            },
          },
        },
      },
    },
  } as any);
}

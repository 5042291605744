import { Dictonary } from './types';

export const en: Dictonary = {
  // JudgeRun
  judging: 'judging',
  selectcompetitor: 'select competitor',
  driver: 'driver',
  gate: 'gate',
  brokenPostText: 'broken',
  gateNotPassedText: 'not passed',
  start: 'start',
  stopTimer: 'stop',
  resumeTimer: 'resume',
  finish: 'finish',
  finishRunConfirm: 'Sure to finish the run? Results can not be modified later!',
  dnfConfirm: 'Sure to mark driver as eliminated and abort the run?',

  // ResultMonitor
  resultTableTitle: 'results',
  overallPoints: 'total points',
  time: 'time',
  autorenewOnTooltip: 'automatic data update is active',
  autoRenewOffTooltip: 'automatic data update is disabled',

  // SignInForm
  loginPassword: 'password',
  loginFailMsg: 'invalid name or password',

  // stdForm
  fieldRequiredHint: 'field is mandatory',
  agree: 'agree',
  cancel: 'cancel',
  continue: 'continue',
  reset: 'reset',
  submit: 'submit',
  new: 'new',
  submitSuccess: 'saved successfully',
  submitError: 'failed to save',
  dataFetchError: 'failed to fetch data',
  deleteError: 'failed to delete',

  deleteConfirmQuestion: (name: string) => `Sure to delete "${name}"? This can't be undone!`,

  // MuiDataTables
  noDataFound: 'no data found',
  columnHeaderTooltip: (label: string) => `sort by ${label}`,
  nextPage: 'next page',
  previousPage: 'previous page',
  rowsPerPage: 'rows per page:',
  displayRows: 'of',
  jumpToPage: 'page',
  search: 'search',
  print: 'print',
  viewColumns: 'view columns',
  filter: 'filter',
  all: 'all',

  // Competition
  competition: 'competition',

  // CompetitionForm
  compName: 'competition name',
  compStartDate: 'competition start',
  compEndDate: 'competition end',
  compConfiguration: 'competition configuration',
  gateCount: 'no. of gates',
  timelimit: 'timeout',
  touchPostPoints: 'points touch',
  breakPostPoints: 'points broken',
  gateNotPassedPoints: 'points not passed',
  useHandPoints: 'points hand',
  leftPostColor: 'left post color',
  rightPostColor: 'right post color',
  specialStage: 'special stage',
  name: 'name',
  startGate: 'starting gate',
  endGate: 'ending gate',
  targetTime: 'time target',
  vehicleWidth: 'vehicle width',
  vehicleHeight: 'vehicle height',
  vehicleWheelDiameter: 'wheel diameter',

  // Competitors
  competitors: 'competitors',
  competitorNumber: 'competitor no.',
  group: 'group',
  startTime: 'start time',

  // CompetitorImporter
  importCompetitors: 'import competitors',
  importCompetitorsFileDescription:
    'Please choose .csv file to import (must include columns "Vorname" and "Nachname")',
  selectFile: 'select file',
  readyForImport: 'ready for import',
  newGroupName: 'new group name',
  addToExistingGroup: 'add to existing group',
  firstStartTime: 'first start',
  startInterval: 'start interval',
  csvParserError: 'parsing .csv failed',
  requiredColumnMissing: (columnName: string) => `Invalid File: Column ${columnName} is missing!`,
  emptyRecordError: 'Invalid File: Contains entry with empty Name',

  // UserTable
  user: 'user',
  users: 'users',

  // UserForm
  firstName: 'firstname',
  lastName: 'lastname',
  loginName: 'login name',
  rockcrawlerDeName: 'rockcrawler user',
  roles: 'user roles',

  // PenaltyBonusInput
  lowerThreshold: 'lower threshold',
  upperThreshold: 'upper threshold',
  lowerPenaltyDelta: 'lower Δ',
  upperPenaltyDelta: 'upper Δ',
  pointsPerLowerDelta: 'points per lower Δ',
  pointsPerUpperDelta: 'points per upper Δ',
};

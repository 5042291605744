import axiosInstance from '../../../config/axiosConfig';

const deleteCompetion = async (compId: string) => {
  try {
    await axiosInstance.delete('/competitions/competition', { params: { compId } });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default deleteCompetion;

import axiosInstance from '../../../config/axiosConfig';

const deleteCompetionResults = async (id: string) => {
  try {
    await axiosInstance.delete('/competitions/competition/results', { params: { id } });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default deleteCompetionResults;

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  DialogContentText,
  TextField,
  InputAdornment,
  Checkbox,
} from '@mui/material';
import { useContext, useState } from 'react';
import Papa from 'papaparse';

import { useSnackbar } from 'notistack';
import { User, UserRole } from 'digital-judge-base/src/types/User';
import FormControlLabel from '@mui/material/FormControlLabel';
import FileImporter from '../../../components/FileImporter';
import { LanguageContext } from '../../../LanguageProvider';
import CustomDateTimePicker from '../../../components/CustomDateTimePicker';
import { insertDrivers } from '../../Users/services/insertDrivers';
import addCompetitors from '../services/addCompetitors';
import { CompetitorGroupSelector } from './CompetitorGroupSelector';
import addGroup from '../services/addGroup';

interface Props {
  compId: string | undefined;
  onImportDone: () => void;
}

export function CompetitorImporter(props: Props) {
  const { compId, onImportDone } = props;

  const { dictionary } = useContext(LanguageContext);
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState<string>('');
  const [useExistingGroup, setUseExistingGroup] = useState<boolean>(false);
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');
  const [pendingDrivers, setPendingDrivers] = useState<User[]>([]);
  const [firstStart, setFirstStart] = useState(new Date());
  const [startInterval, setStartInterval] = useState(0);

  const handleClose = () => {
    setOpen(false);
    setPendingDrivers([]);
  };

  const handleSave = async () => {
    if (!compId) {
      return;
    }

    const newDrivers = await insertDrivers(pendingDrivers);
    if (!newDrivers) {
      enqueueSnackbar(dictionary.submitError, { variant: 'error' });
      return;
    }

    let groupId = selectedGroupId;
    if (!useExistingGroup) {
      const result = await addGroup(compId, newGroupName);
      const newGroupId = result?.competitorGroups[result.competitorGroups.length - 1]._id;
      if (!newGroupId) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
        return;
      }
      groupId = newGroupId;
    }

    const success = await addCompetitors(
      compId,
      groupId,
      newDrivers.map(d => d._id as string),
      firstStart,
      startInterval * 60
    );
    if (!success) {
      enqueueSnackbar(dictionary.submitError, { variant: 'error' });
      return;
    }

    enqueueSnackbar(dictionary.submitSuccess, { variant: 'success' });
    onImportDone();
    handleClose();
  };

  const handleFileChange = (data: string) => {
    // ***** Parse .csv *****
    const result = Papa.parse<any>(data, { header: true, skipEmptyLines: true });
    if (result.errors.length > 0) {
      console.log(data);
      console.log(result.errors);
      enqueueSnackbar(dictionary.csvParserError, { variant: 'error' });
      return;
    }

    // ***** Create User objects from Data *****
    const newDrivers: User[] = [];
    for (const record of result.data) {
      let firstName = '';
      let lastName = '';

      if (typeof record.Vorname !== 'string') {
        enqueueSnackbar(dictionary.requiredColumnMissing('Vorname'), { variant: 'error' });
        return;
      }
      if (typeof record.Nachname !== 'string') {
        enqueueSnackbar(dictionary.requiredColumnMissing('Nachname'), { variant: 'error' });
        return;
      }

      firstName = record.Vorname;
      lastName = record.Nachname;
      if (firstName === '' || lastName === '') {
        enqueueSnackbar(dictionary.emptyRecordError, { variant: 'error' });
        return;
      }

      const driver = {
        firstName,
        lastName,
        rockcrawlerDeName: '',
        roles: [UserRole.Driver],
        vehicleIds: [],
      };
      newDrivers.push(driver);
    } // ENDfor
    setPendingDrivers(newDrivers);
  };

  return (
    <div>
      <Button size="small" variant="contained" onClick={() => setOpen(true)}>
        Import
      </Button>

      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>{dictionary.importCompetitors}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dictionary.importCompetitorsFileDescription}</DialogContentText>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              width: '230px',
              marginTop: '20px',
            }}
          >
            <FileImporter
              importButtonLabel={dictionary.selectFile}
              acceptedFiletype="csv"
              onFileLoad={handleFileChange}
            />
            <Typography
              color={pendingDrivers.length > 0 ? 'green' : 'red'}
            >{`${pendingDrivers.length} ${dictionary.readyForImport}`}</Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={useExistingGroup}
                  onChange={event => setUseExistingGroup(event.target.checked)}
                />
              }
              label={dictionary.addToExistingGroup}
            />
            {useExistingGroup ? (
              <CompetitorGroupSelector
                compId={compId}
                selectedGroupId={selectedGroupId}
                onGroupSelected={setSelectedGroupId}
              />
            ) : (
              <TextField
                label={dictionary.newGroupName}
                value={newGroupName}
                error={!newGroupName}
                onChange={event => setNewGroupName(event.target.value)}
              />
            )}

            <CustomDateTimePicker
              label={dictionary.firstStartTime}
              value={firstStart}
              onChange={newVal => setFirstStart(newVal)}
            />

            <TextField
              value={startInterval}
              onChange={event => setStartInterval(Math.round(Number(event.target.value)))}
              type="number"
              label={dictionary.startInterval}
              InputProps={{
                endAdornment: <InputAdornment position="end">min</InputAdornment>,
              }}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>{dictionary.cancel}</Button>
          <Button
            onClick={handleSave}
            disabled={
              pendingDrivers.length === 0 ||
              (useExistingGroup && !selectedGroupId) ||
              (!useExistingGroup && !newGroupName)
            }
          >
            {dictionary.submit}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { AxiosResponse } from 'axios';
import { User } from 'digital-judge-base/src/types/User';
import axiosInstance from '../../../config/axiosConfig';
import { LoginData } from '../types';

const loginUser = async (data: LoginData) => {
  try {
    const response: AxiosResponse<User> = await axiosInstance.post('/users/login', data);
    if (!response.data) {
      console.log('Login fehlgeschlagen');
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default loginUser;

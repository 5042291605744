import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { ALL_VAL } from 'digital-judge-base/src/constants';
import { CompetitorGroup } from 'digital-judge-base/src/types/Competition';
import { LanguageContext } from '../../../LanguageProvider';
import fetchCompetitionGroups from '../services/fetchCompetitionGroups';

interface Props {
  compId: string | undefined;
  selectedGroupId: string;
  onGroupSelected: (selectedId: string) => void;
  disabled?: boolean;
  defaultAll?: boolean;
}

export function CompetitorGroupSelector(props: Props) {
  const { compId, selectedGroupId, onGroupSelected, disabled, defaultAll } = props;

  const { dictionary } = useContext(LanguageContext);

  const [selectableGroups, setSelectableGroups] = useState<CompetitorGroup[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!compId) {
        return;
      }
      const groups = await fetchCompetitionGroups(compId);
      if (!groups) {
        return;
      }
      setSelectableGroups(groups);
    };
    fetchData();
  }, [compId]);

  return (
    <FormControl
      style={{ minWidth: '150px' }}
      error={!disabled && !defaultAll && !selectedGroupId}
      disabled={disabled}
    >
      <InputLabel id="groupSelID">{dictionary.group}</InputLabel>
      <Select
        size={defaultAll ? 'small' : undefined}
        labelId="groupSelID"
        label={dictionary.group}
        onChange={event => onGroupSelected(event.target.value)}
        renderValue={val =>
          val === ALL_VAL ? dictionary.all : selectableGroups.find(comp => comp._id === val)?.name
        }
        value={selectedGroupId}
      >
        {defaultAll && (
          <MenuItem value={ALL_VAL} key={ALL_VAL}>
            {dictionary.all}
          </MenuItem>
        )}
        {selectableGroups.map(group => (
          <MenuItem value={group._id} key={group._id}>
            {group.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

CompetitorGroupSelector.defaultProps = {
  disabled: false,
  defaultAll: false,
};

import { useContext, useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';

import { Button, Grid, IconButton } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  Competition,
  CompetitorInfo,
  CompetitorState,
} from 'digital-judge-base/src/types/Competition';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import { useSnackbar } from 'notistack';
import { Vehicle } from 'digital-judge-base/src/types/Vehicle';
import { ALL_VAL } from 'digital-judge-base/src/constants';
import fetchCompetitors from '../Competitions/services/fetchCompetitors';
import { LanguageContext } from '../../LanguageProvider';
import { RoutingPath } from '../../App/Routes';
import CompetitionSelector from '../../components/CompetitionSelector';
import findVehicle from '../Vehicles/services/findVehicle';
import VehicleForm from '../Vehicles/VehicleForm';
import updateVehicle from '../Vehicles/services/updateVehicle';
import insertCompetitorVehicle from '../Vehicles/services/insertCompetitorVehicle';
import { getMuiTableBaseOptions, getMuiTableTheme } from '../../config/MuiTableConfig';
import { CompetitorGroupSelector } from '../Competitions/Competitors/CompetitorGroupSelector';

const initialVehicle: Vehicle = {
  isStockType: false,
  width_mm: 0,
  height_mm: 0,
  wheelDiameter_mm: 0,
  name: '',
};

function Preparation() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { dictionary } = useContext(LanguageContext);

  const [drivers, setDrivers] = useState<CompetitorInfo[]>([]);
  const [groupId, setGroupId] = useState<string>(ALL_VAL);
  const [vehicleData, setVehicleData] = useState<{ vehicle: Vehicle; driverId: string } | null>(
    null
  );

  const fetchDriverList = async () => {
    if (!urlParams.comp) {
      setDrivers([]);
      return;
    }
    const list = await fetchCompetitors(urlParams.comp);
    if (!list) {
      setDrivers([]);
      enqueueSnackbar(dictionary.dataFetchError, { variant: 'error' });
      return;
    }
    setDrivers(list.filter(c => c.state !== CompetitorState.Finished));
  };

  useEffect(() => {
    fetchDriverList();
  }, [urlParams]);

  const onCompSelected = (comp: Competition) => {
    setGroupId(ALL_VAL);
    navigate(generatePath(RoutingPath.JudgeRunPrepP, { comp: comp._id }));
  };

  const handleVehicleEdit = async (driverId: string) => {
    const driver = drivers.find(d => d.user._id === driverId);
    if (!driver) {
      return;
    }

    if (driver.vehicleId) {
      const v = await findVehicle(driver.vehicleId);
      if (!v) {
        enqueueSnackbar(dictionary.dataFetchError, { variant: 'error' });
        return;
      }
      setVehicleData({ vehicle: v, driverId });
    } else {
      setVehicleData({ vehicle: initialVehicle, driverId });
    }
  };

  const handleVehicleSubmit = async (newVehicle: Vehicle) => {
    if (newVehicle._id) {
      // update
      const success = await updateVehicle(newVehicle._id, newVehicle);
      if (success) {
        await fetchDriverList();
        setVehicleData(null);
      } else {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
      }
    } else {
      // neu anlegen
      if (!urlParams.comp || !vehicleData) {
        return;
      }
      const result = await insertCompetitorVehicle(
        newVehicle,
        urlParams.comp,
        vehicleData.driverId
      );
      if (result) {
        await fetchDriverList();
        setVehicleData(null);
      } else {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
      }
    }
  };

  const handleStartJudging = (driverId: string) => {
    if (!urlParams.comp) {
      return;
    }
    navigate(generatePath(RoutingPath.JudgeRunP, { comp: urlParams.comp, driver: driverId }));
  };

  const columns: MUIDataTableColumnDef[] = [
    dictionary.competitorNumber,
    dictionary.firstName,
    dictionary.lastName,
    { name: dictionary.rockcrawlerDeName, options: { display: false } },
    {
      name: '',
      options: {
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        searchable: false,
        customBodyRender: (value: string) => {
          const driver = drivers.find(d => d.user._id === value);
          if (
            driver?.state === CompetitorState.Waiting ||
            driver?.state === CompetitorState.InStage
          ) {
            return (
              <>
                <IconButton
                  color="primary"
                  onClick={() => handleVehicleEdit(value)}
                  sx={{ marginRight: '10px', padding: '3px' }}
                >
                  <DirectionsCarFilledIcon fontSize="large" />
                </IconButton>
                <Button
                  size="small"
                  variant="contained"
                  disabled={!driver.vehicleId}
                  onClick={() => handleStartJudging(value)}
                >
                  {driver?.state === CompetitorState.InStage ? dictionary.continue : 'Start'}
                </Button>
              </>
            );
          }
          // niemals erreicht, weil direkt bei fetch gefilterte Liste
          return 'finished';
        },
      },
    },
  ];
  const data = drivers
    .filter(d => groupId === ALL_VAL || d.groupId === groupId)
    .map(d => [
      d.competitorNo,
      d.user.firstName,
      d.user.lastName,
      d.user.rockcrawlerDeName,
      d.user._id,
    ]);
  const options: MUIDataTableOptions = {
    ...getMuiTableBaseOptions(dictionary),
    search: true,
    viewColumns: true,
    customToolbar: () => (
      <CompetitorGroupSelector
        disabled={!urlParams.comp}
        compId={urlParams.comp}
        selectedGroupId={groupId}
        onGroupSelected={setGroupId}
        defaultAll
      />
    ),
  };

  return (
    <>
      {vehicleData ? (
        <VehicleForm
          initialData={vehicleData.vehicle}
          onCancel={() => setVehicleData(null)}
          onSubmit={handleVehicleSubmit}
        />
      ) : (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CompetitionSelector
              onChange={onCompSelected}
              competitionId={urlParams.comp ? urlParams.comp : ''}
            />
          </Grid>
          <Grid item>
            <ThemeProvider theme={getMuiTableTheme()}>
              <MUIDataTable
                title={dictionary.selectcompetitor}
                data={data}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Preparation;

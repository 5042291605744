import axiosInstance from '../../../config/axiosConfig';

const deleteUser = async (id: string) => {
  try {
    await axiosInstance.delete('/users/user', {
      params: { id },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default deleteUser;

import { CSSProperties, useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Button } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { matchPath } from 'react-router';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { UserRole } from 'digital-judge-base/src/types/User';
import LanguageSelector from '../components/LanguageSelector';
import { UserContext } from './Auth/UserProvider';
import logoutUser from './Auth/services/logoutUser';
import { LanguageContext } from '../LanguageProvider';
import { RoutingPath } from './Routes';
import AboutDialog from './AboutDialog';

interface Style {
  toolbar: CSSProperties;
}

const style: Style = {
  toolbar: {
    minHeight: '50px',
  },
};

export default function MenuAppBar() {
  const { loggedInUser, setLoggedInUser } = useContext(UserContext);
  const { dictionary } = useContext(LanguageContext);
  const navigate = useNavigate();

  // von außerhalb <Routes> an URL Parameter zu kommen ist etwas tricky...
  const { pathname } = useLocation();
  let urlParams: any = {};
  const patternsToTest = [
    RoutingPath.JudgeRunPrepP,
    RoutingPath.JudgeRunP,
    RoutingPath.ResultMonitorP,
    RoutingPath.CompetitorManagerP,
  ];
  for (const pattern of patternsToTest) {
    const urlMatch = matchPath(pattern, pathname);
    if (urlMatch?.params) {
      urlParams = urlMatch.params;
      break;
    }
  } // ENDfor

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky">
      <Toolbar style={style.toolbar}>
        <IconButton
          onClick={event => handleMenu(event)}
          edge="start"
          color="inherit"
          sx={{ mr: 2, visibility: loggedInUser ? 'visible' : 'hidden' }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              if (urlParams?.comp) {
                navigate(generatePath(RoutingPath.JudgeRunPrepP, { comp: urlParams.comp }));
              } else {
                navigate(RoutingPath.JudgeRunPrepBaseP);
              }
              handleClose();
            }}
          >
            {dictionary.judging}
          </MenuItem>

          {loggedInUser?.roles.some(r => r === UserRole.Admin || r === UserRole.Organizer) ? (
            <MenuItem
              onClick={() => {
                navigate(RoutingPath.CompetitionP);
                handleClose();
              }}
            >
              {dictionary.compConfiguration}
            </MenuItem>
          ) : (
            ''
          )}

          <MenuItem
            onClick={() => {
              if (urlParams?.comp) {
                navigate(generatePath(RoutingPath.ResultMonitorP, { comp: urlParams.comp }));
              } else {
                navigate(RoutingPath.ResultMonitorBaseP);
              }
              handleClose();
            }}
          >
            {dictionary.resultTableTitle}
          </MenuItem>

          {loggedInUser?.roles.includes(UserRole.Admin) ? (
            <MenuItem
              onClick={() => {
                navigate(RoutingPath.UserManagerP);
                handleClose();
              }}
            >
              {dictionary.users}
            </MenuItem>
          ) : (
            ''
          )}
        </Menu>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Digital-Judge
        </Typography>
        {!loggedInUser ? (
          <Button color="inherit" onClick={() => navigate(RoutingPath.LoginP)}>
            Login
          </Button>
        ) : (
          <Button
            color="inherit"
            onClick={() => {
              logoutUser();
              setLoggedInUser(null);
            }}
          >
            Logout
          </Button>
        )}
        <LanguageSelector />
        <AboutDialog />
      </Toolbar>
    </AppBar>
  );
}

import { ToggleButton } from '@mui/material';
import { CSSProperties, useContext } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

import CounterIncDec from '../../components/CounterIncDec';
import { LanguageContext } from '../../LanguageProvider';

const GatePostGraphic = (props: { color: string; isBroken: boolean }) => {
  const { color, isBroken } = props;

  const postStyle = {
    content: '',
    width: '8px',
    minHeight: '110px',
    borderLeft: '1px solid gray',
    borderRight: '2px solid gray',
    borderBottom: '1px solid gray',
    backgroundColor: 'lightgray',
  };

  const tipStyle = {
    content: '',
    width: '11px',
    height: '30px',
    backgroundColor: `${color}`,
  };

  return (
    <div style={{ transform: `rotate(${isBroken ? '-45' : '0'}deg)` }}>
      <div style={tipStyle} />
      <div style={postStyle} />
    </div>
  );
};

interface Styles {
  container: CSSProperties;
}

const style: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    height: '100%',
    alignItems: 'center',
    width: 'fit-content',
  },
};

export interface GatePostData {
  touchCount: number;
  isBroken: boolean;
}

interface Props {
  disabled: boolean;
  value: GatePostData;
  color: string;
  onChange: (newData: GatePostData) => void;
}

function GatePost(props: Props) {
  const { disabled, value, color, onChange } = props;

  const { dictionary } = useContext(LanguageContext);

  return (
    <div style={style.container}>
      <GatePostGraphic color={color} isBroken={value.isBroken} />
      <CounterIncDec
        disabled={disabled}
        value={value.touchCount}
        onChange={newVal => {
          onChange({ isBroken: value.isBroken, touchCount: newVal });
        }}
      />
      <ToggleButton
        value=""
        size="small"
        color="error"
        selected={value.isBroken}
        disabled={disabled}
        onChange={() => {
          onChange({ isBroken: !value.isBroken, touchCount: value.touchCount });
        }}
      >
        <CancelIcon />
        {dictionary.brokenPostText}
      </ToggleButton>
    </div>
  );
}

export default GatePost;

import { User } from 'digital-judge-base/src/types/User';
import axiosInstance from '../../../config/axiosConfig';

const updateUser = async (data: Partial<User>) => {
  try {
    await axiosInstance.post('/users/user', data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default updateUser;

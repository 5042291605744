import { AxiosResponse } from 'axios';
import { Competition } from 'digital-judge-base/src/types/Competition';
import axiosInstance from '../../../config/axiosConfig';

const fetchCompetitions = async () => {
  try {
    const response: AxiosResponse<Competition[]> = await axiosInstance.get('/competitions');
    if (!response.data) {
      console.log('fetchCompetitions fehlgeschlagen');
      return null;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default fetchCompetitions;

import { AxiosResponse } from 'axios';
import { Vehicle } from 'digital-judge-base/src/types/Vehicle';
import axiosInstance from '../../../config/axiosConfig';

const insertCompetitorVehicle = async (newVehicle: Vehicle, compId: string, driverId: string) => {
  try {
    const response: AxiosResponse<Vehicle> = await axiosInstance.post(
      '/vehicles/competitor-vehicle',
      {
        newVehicle,
        compId,
        driverId,
      }
    );
    if (!response.data) {
      console.log('insertCompetitorVehicle fehlgeschlagen');
      return null;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default insertCompetitorVehicle;

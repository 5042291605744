import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { User, UserRole } from 'digital-judge-base/src/types/User';

import { CSSProperties, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LanguageContext } from '../../LanguageProvider';

interface Style {
  container: CSSProperties;
}

const style: Style = {
  container: {
    marginTop: '30px',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
  },
};

interface Props {
  initialData: User;
  onCancel: () => void;
  onSubmit: (user: User) => void;
}

function UserForm(props: Props) {
  const { initialData, onCancel, onSubmit } = props;

  const { dictionary } = useContext(LanguageContext);
  const { control, reset, handleSubmit } = useForm({
    defaultValues: initialData,
  });

  return (
    <form style={style.container} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="firstName"
        control={control}
        rules={{ required: dictionary.fieldRequiredHint }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.firstName}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        rules={{ required: dictionary.fieldRequiredHint }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.lastName}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="loginName"
        control={control}
        rules={{ required: dictionary.fieldRequiredHint }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.loginName}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="rockcrawlerDeName"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={dictionary.rockcrawlerDeName}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        rules={initialData._id ? undefined : { required: dictionary.fieldRequiredHint }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type="password"
            label={dictionary.loginPassword}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
      <Controller
        name="roles"
        control={control}
        rules={{ required: dictionary.fieldRequiredHint }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl sx={{ minWidth: '200px' }}>
            <InputLabel>{dictionary.roles}</InputLabel>
            <Select
              label={dictionary.roles}
              multiple
              renderValue={selected => selected.join(', ')}
              onChange={onChange}
              value={value}
              error={!!error}
            >
              {Object.entries(UserRole)
                .filter(([, name]) => name !== UserRole.Driver)
                .map(([id, name]) => (
                  <MenuItem key={id} value={name}>
                    <Checkbox checked={value.indexOf(name) > -1} />
                    <ListItemText primary={id} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      />

      <Grid container spacing={2} justifyContent="center">
        <Grid item sm="auto">
          <Button onClick={onCancel}>{dictionary.cancel}</Button>
        </Grid>
        <Grid item sm="auto">
          <Button type="reset" onClick={() => reset()}>
            {dictionary.reset}
          </Button>
        </Grid>
        <Grid item sm="auto">
          <Button type="submit">{dictionary.submit}</Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default UserForm;

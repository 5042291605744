import { Competition } from 'digital-judge-base/src/types/Competition';
import axiosInstance from '../../../config/axiosConfig';

const addGroup = async (compId: string, newGroupName: string): Promise<Competition | undefined> => {
  try {
    const result = await axiosInstance.post('/competitions/competition/group', {
      compId,
      newGroupName,
    });
    return result.data;
  } catch (error) {
    return undefined;
  }
};

export default addGroup;

import { AxiosResponse } from 'axios';
import { Vehicle } from 'digital-judge-base/src/types/Vehicle';
import axiosInstance from '../../../config/axiosConfig';

const findVehicle = async (id: string) => {
  try {
    const response: AxiosResponse<Vehicle> = await axiosInstance.get('/vehicles/vehicle', {
      params: { id },
    });
    if (!response.data) {
      console.log('findVehicle fehlgeschlagen');
      return null;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default findVehicle;

import { User } from 'digital-judge-base/src/types/User';
import { createContext, useEffect, useState } from 'react';
import getLoggedInUser from './services/getLoggedInUser';

interface UserContextProvider {
  loggedInUser: User | null;
  setLoggedInUser: (user: User | null) => void;
}

export const UserContext = createContext<UserContextProvider>({
  loggedInUser: null,
  setLoggedInUser: () => {},
});

export function UserProvider({ children }: any) {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      const u = await getLoggedInUser();
      setUser(u);
    };
    fetchUser();
  }, []);

  const provider: UserContextProvider = {
    loggedInUser: user,
    setLoggedInUser: setUser,
  };

  return <UserContext.Provider value={provider}>{children}</UserContext.Provider>;
}

import { Grid, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import { Competition } from 'digital-judge-base/src/types/Competition';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/styles';
import { LanguageContext } from '../../LanguageProvider';

import CompetitionForm from './CompetitionForm';
import { initialCompetition } from './constants';
import fetchCompetitions from './services/fetchCompetitions';
import insertCompetition from './services/insertCompetition';
import updateCompetition from './services/updateCompetition';
import deleteCompetitionResults from './services/deleteCompetionResults';
import DeleteButton from '../../components/DeleteButton';
import { hasResults } from './services/hasResults';
import { RoutingPath } from '../../App/Routes';
import deleteCompetition from './services/deleteCompetion';
import { getMuiTableBaseOptions, getMuiTableTheme } from '../../config/MuiTableConfig';
import AddButton from '../../components/AddButton';

function Competitions() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { dictionary } = useContext(LanguageContext);

  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [selectedComp, setSelectedComp] = useState<Competition | null>(null);

  const fetch = async () => {
    const data = await fetchCompetitions();
    if (data) {
      setCompetitions(data);
    } else {
      console.log('fetchError');
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const onSubmit = async (data: Competition) => {
    if (!data._id) {
      const comp = await insertCompetition(data);
      if (!comp) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
        return;
      }
    } else {
      const success = await updateCompetition(data);
      if (!success) {
        enqueueSnackbar(dictionary.submitError, { variant: 'error' });
        return;
      }
    }
    fetch();
    setSelectedComp(null);
  };

  const onEditClicked = (compId: String) => {
    const comp = competitions.find(d => d._id === compId);
    if (comp) {
      setSelectedComp(comp);
    }
  };

  const onCompetitorsClicked = (compId: string) => {
    navigate(generatePath(RoutingPath.CompetitorManagerP, { comp: compId }));
  };

  const onDeleteResultsClicked = async (compId: string) => {
    const success = await deleteCompetitionResults(compId);
    if (!success) {
      enqueueSnackbar(dictionary.deleteError, { variant: 'error' });
    }
    fetch();
  };

  const onDeleteCompetitionClicked = async (compId: string) => {
    const success = await deleteCompetition(compId);
    if (!success) {
      enqueueSnackbar(dictionary.deleteError, { variant: 'error' });
      return;
    }
    fetch();
  };

  const columns = [
    dictionary.compName,
    {
      name: '',
      options: {
        customBodyRender: (value: string) => {
          const comp = competitions.find(c => c._id === value);
          if (!comp) {
            return 'INTERNAL_ERROR';
          }
          return (
            <Grid container spacing={1}>
              <Grid item>
                <IconButton size="small" color="primary" onClick={() => onEditClicked(value)}>
                  <EditIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => onCompetitorsClicked(value)}
                >
                  <GroupIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <DeleteButton
                  label={dictionary.resultTableTitle}
                  confirmName={`${comp.name}: ${dictionary.resultTableTitle}`}
                  onClick={() => onDeleteResultsClicked(value)}
                  disabled={!hasResults(comp)}
                />
              </Grid>
              <Grid item>
                <DeleteButton
                  label={dictionary.competition}
                  confirmName={comp ? comp.name : dictionary.competition}
                  onClick={() => onDeleteCompetitionClicked(value)}
                  disabled={hasResults(comp)}
                />
              </Grid>
            </Grid>
          );
        },
      },
    },
  ];
  const data = competitions.map(c => [c.name, c._id]);
  const options: MUIDataTableOptions = {
    ...getMuiTableBaseOptions(dictionary),
    customToolbar: () => (
      <AddButton
        onClick={() => setSelectedComp(initialCompetition)}
        label={dictionary.competition}
      />
    ),
  };

  return (
    <>
      {selectedComp ? (
        <CompetitionForm
          initialData={selectedComp}
          onSubmit={onSubmit}
          onCancel={() => setSelectedComp(null)}
        />
      ) : (
        <ThemeProvider theme={getMuiTableTheme()}>
          <MUIDataTable
            title={dictionary.compConfiguration}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      )}
    </>
  );
}

export default Competitions;

import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useContext } from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import { LanguageContext } from '../LanguageProvider';

interface Props {
  label?: string;
  confirmName: string;
  onClick: () => void;
  disabled?: boolean;
}

export default function DeleteButton(props: Props) {
  const { label, onClick, confirmName, disabled } = props;

  const { dictionary } = useContext(LanguageContext);

  const [confirmPending, setConfirmPending] = useState(false);

  const handleSubmit = (agree: boolean) => {
    if (agree) {
      onClick();
    }
    setConfirmPending(false);
  };

  return (
    <>
      {label ? (
        <Button
          variant="outlined"
          size="small"
          color="error"
          disabled={disabled}
          onClick={() => setConfirmPending(true)}
          startIcon={<DeleteIcon />}
        >
          {label}
        </Button>
      ) : (
        <IconButton
          size="small"
          color="primary"
          disabled={disabled}
          onClick={() => setConfirmPending(true)}
        >
          <DeleteIcon />
        </IconButton>
      )}
      <ConfirmationDialog
        open={confirmPending}
        text={dictionary.deleteConfirmQuestion(confirmName)}
        onSubmit={handleSubmit}
      />
    </>
  );
}

DeleteButton.defaultProps = {
  disabled: false,
  label: undefined,
};

import { AxiosResponse } from 'axios';
import { AggregatedResult } from 'digital-judge-base/src/types/StageResult';
import axiosInstance from '../../../config/axiosConfig';

const fetchResultSummary = async (competitionId: string, groupId: string) => {
  try {
    const response: AxiosResponse<AggregatedResult[]> = await axiosInstance.get('/stageresults', {
      params: { competitionId, groupId },
    });
    if (!response.data) {
      return undefined;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export default fetchResultSummary;

import { AxiosResponse } from 'axios';
import { User } from 'digital-judge-base/src/types/User';
import axiosInstance from '../../../config/axiosConfig';

const insertUser = async (data: User) => {
  try {
    const response: AxiosResponse<User> = await axiosInstance.post('/users', data);
    if (!response.data) {
      console.log('insertUser fehlgeschlagen');
      return null;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default insertUser;

import {
  Competition,
  vehicleHeightParam,
  vehicleWheelDiaParam,
  vehicleWidthParam,
} from 'digital-judge-base/src/types/Competition';

const initialPenaltyBonusThreshold = {
  name: '',
  unit: 'mm',
  lowerThreshold: 0,
  upperThreshold: 0,
  lowerPenaltyDelta: 0,
  pointsPerLowerDelta: 0,
  upperPenaltyDelta: 0,
  pointsPerUpperDelta: 0,
};

const initialCompConfig = {
  gateCount: 0,
  timelimit_s: 0,
  touchPostPoints: 0,
  breakPostPoints: 0,
  notPassGatePoints: 0,
  useHandPoints: 0,
  leftPostColor: '#FF0000',
  rightPostColor: '#0000FF',
  specialPenaltyOrBonus: [
    { ...initialPenaltyBonusThreshold, name: vehicleWidthParam },
    { ...initialPenaltyBonusThreshold, name: vehicleHeightParam },
    { ...initialPenaltyBonusThreshold, name: vehicleWheelDiaParam },
  ],
  specialStages: [],
};

export const initialCompetition: Competition = {
  isArchived: false,
  name: '',
  startDate: new Date(),
  endDate: new Date(),
  compConfig: initialCompConfig,
  competitorGroups: [],
  competitors: [],
};

export enum RoutingPath {
  LoginP = '/login',
  JudgeRunPrepBaseP = '/judgerun/preparation',
  JudgeRunPrepP = '/judgerun/preparation/:comp',
  JudgeRunP = '/judgerun/:comp/:driver',
  CompetitionP = '/competition',
  ResultMonitorBaseP = '/results',
  ResultMonitorP = '/results/:comp',
  CompetitorManagerP = '/competition/:comp/competitors',
  UserManagerP = '/users',
}

import { AxiosResponse } from 'axios';
import { CompetitorGroup } from 'digital-judge-base/src/types/Competition';
import axiosInstance from '../../../config/axiosConfig';

const fetchCompetitionGroups = async (compId: string) => {
  try {
    const response: AxiosResponse<CompetitorGroup[]> = await axiosInstance.get(
      `/competitions/${compId}/groups`
    );
    if (!Array.isArray(response.data)) {
      return undefined;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export default fetchCompetitionGroups;

import { Competition, CompetitorFormData } from 'digital-judge-base/src/types/Competition';
import axiosInstance from '../../../config/axiosConfig';

const updateCompetitor = async (
  compId: string,
  formData: CompetitorFormData
): Promise<Competition | undefined> => {
  try {
    const result = await axiosInstance.post('/competitions/competition/competitors/update', {
      compId,
      formData,
    });
    return result.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export default updateCompetitor;

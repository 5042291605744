"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompetitorState = exports.specialStageTimeParam = exports.vehicleWheelDiaParam = exports.vehicleHeightParam = exports.vehicleWidthParam = void 0;
exports.vehicleWidthParam = 'vehicleWidth_mm';
exports.vehicleHeightParam = 'vehicleHeight_mm';
exports.vehicleWheelDiaParam = 'vehicleWheelDiameter_mm';
exports.specialStageTimeParam = 'timeTarget_sec';
/** Status eines Teilnehmers */
var CompetitorState;
(function (CompetitorState) {
    CompetitorState["Waiting"] = "waiting";
    CompetitorState["InStage"] = "inStage";
    CompetitorState["Finished"] = "finished";
})(CompetitorState = exports.CompetitorState || (exports.CompetitorState = {}));

import { AxiosResponse } from 'axios';
import { StageResult } from 'digital-judge-base/src/types/StageResult';
import axiosInstance from '../../../config/axiosConfig';

/** retuns null on no Result available and undefined on error */
const fetchDriverResult = async (competitionId: string, driverId: string) => {
  try {
    const response: AxiosResponse<StageResult> = await axiosInstance.get(
      '/stageresults/stageresult/driver',
      {
        params: { competitionId, driverId },
      }
    );
    if (response.status === 204) {
      return null;
    }
    if (!response.data) {
      console.log('fetchDriverResult fehlgeschlagen');
      return undefined;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export default fetchDriverResult;

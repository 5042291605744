import axios, { AxiosInstance } from 'axios';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASEURL,
  withCredentials: true,
  // timeout: 1000,
  // headers: {
  //   'Accept-Language': 'en',
  // },
});

export default axiosInstance;

import { IconButton, Tooltip } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useContext } from 'react';
import { LanguageContext } from '../LanguageProvider';

interface Props {
  isSet: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export default function AutorenewIconButton(props: Props) {
  const { onClick, isSet, disabled } = props;
  const { dictionary } = useContext(LanguageContext);

  return (
    <Tooltip title={isSet ? dictionary.autorenewOnTooltip : dictionary.autoRenewOffTooltip}>
      <IconButton color={isSet ? 'success' : 'primary'} onClick={onClick} disabled={disabled}>
        <AutorenewIcon />
      </IconButton>
    </Tooltip>
  );
}

AutorenewIconButton.defaultProps = {
  disabled: false,
};

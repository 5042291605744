import { AxiosResponse } from 'axios';
import { StageResult } from 'digital-judge-base/src/types/StageResult';
import axiosInstance from '../../../config/axiosConfig';

const finishRun = async (id: string, isDnf: boolean) => {
  try {
    const response: AxiosResponse<StageResult> = await axiosInstance.post(
      '/stageresults/stageresult/finishrun',
      { id, isDnf }
    );
    if (!response.data) {
      console.log('finishRun fehlgeschlagen');
      return undefined;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export default finishRun;

import { Button, TextField, Typography } from '@mui/material';
import { CSSProperties, useContext, useState } from 'react';
import { LanguageContext } from '../../LanguageProvider';

import loginUser from './services/loginUser';
import { UserContext } from './UserProvider';

interface Style {
  container: CSSProperties;
}

const style: Style = {
  container: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default () => {
  const { dictionary } = useContext(LanguageContext);
  const { setLoggedInUser } = useContext(UserContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isFailed, setIsFailed] = useState(false);

  const onSubmit = async () => {
    const loggedInUser = await loginUser({ username, password });
    if (loggedInUser === null) {
      setIsFailed(true);
    } else {
      setIsFailed(false);
      setLoggedInUser(loggedInUser);
      // TODO navigate on Success, if currentRoute is LoginRoute
    }
  };

  return (
    <div style={style.container}>
      <TextField
        label={dictionary.loginName}
        value={username}
        onChange={event => setUsername(event.target.value)}
        error={isFailed}
      />
      <TextField
        label={dictionary.loginPassword}
        type="password"
        value={password}
        onChange={event => setPassword(event.target.value)}
        onKeyPress={event => (event.key === 'Enter' ? onSubmit() : '')}
        error={isFailed}
      />
      <Button variant="outlined" onClick={() => onSubmit()}>
        Login
      </Button>

      <Typography variant="subtitle2" visibility={isFailed ? 'visible' : 'hidden'} color="#f44336">
        {dictionary.loginFailMsg}
      </Typography>
    </div>
  );
};
